import React, { useState } from "react";
import cx from "classnames";
import { Route, Link } from "react-router-dom";
import { usePagination } from "seed/gql";
import List from "components/search/List";
import Details from "./Details";
import Modal from 'seed/helpers/Modal';
import Loading from 'seed/helpers/Loading';
import styles from "styles/css/search/Search.module.css";

const PUBLICATIONS = `
{
  publicationPagination {
    totalPages
    publications {
      type
      situation
      name
      publishDate
      rfc
      alerts {
        transaction {
          client { }
        }
      }
    }
  }
}
`

function Search(props)
{
  const { path } = props.match;
  const { search = "" } = props;
  const [pageNum, setPageNum] = useState(1);
  const query = `rfc.startswith=${search.toUpperCase()} OR name.contains=${search.toUpperCase()}`;
  const qPublications = usePagination(PUBLICATIONS, pageNum, 42, query);

  if (qPublications.loading) return <Loading />;
  if (qPublications.error || qPublications.error) return "Error";

  let { publications = [], totalPages=0 } = qPublications.data.publicationPagination;
  // In case of same date use id
  let publicationSorted = [...publications]
  publicationSorted.sort((p1, p2) => 
    ((new Date(p1.publishDate)).getTime() - p1.id) - ((new Date(p2.publishDate)).getTime() - p2.id))

  let unique = [];
  let uniqueRfcs = {}
  publicationSorted.forEach(function(i) {
    if(!uniqueRfcs[i.rfc]) {
      uniqueRfcs[i.rfc] = true;
      unique.push(i);
    }
  });
  publicationSorted = [...unique];
  
  const DetailsModal = (props) =>
    <Modal {...props}
      width={700}
      height={450}>
      <Details {...props} />
    </Modal>

  const onClickPage = i =>
    setPageNum(i);
  
  
  let pages = [];
  const pageCount = totalPages;
  let pageIni = pageNum - 7 >= 1 ? pageNum - 7 : 1;

  if (pageIni > 1)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(pageIni - 1)}
        style={{ background: "#fff" }}>{"<"}</div>)

  for (let i = pageIni; i <= pageIni + 13 && i <= pageCount; i++)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(i)}
        style={{ background: pageNum == i ? "#f5f5f5" : "#fff" }}>{i}</div>)

  if (pageCount > pageIni + 13)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(pageIni + 14)}
        style={{ background: "#fff" }}>{">"}</div>)

  return (
    <div className={cx("card", styles.heighthundred, styles.box)}>
      <div className={cx("card-header", styles.header, styles.row)}>
        <div className={cx(styles.titleContainer)}>
          <p className={cx(styles.title)}>Búsqueda <span>Art. 69 & 69B CFF</span></p>
        </div>
      </div>
      <div className={cx(styles.row, styles.content, styles.listContainer)}>
      <List
          publications={publicationSorted}
          match={props.match}
          history={props.history}
        />
        <div className={styles.legend}>
          {
          pageCount == 0 ?
              <div><i class="fas fa-search"></i><br />No se encontraron resultados</div> :
              null
          }
      </div>
      </div>
      {pages.length > 1 ?
        <div className={styles.pages}>
          {pages}
        </div> : null
      }
      <Route path={`${path}/details/:rfc`}
        component={DetailsModal} />
    </div>
  );
}

export default Search;
