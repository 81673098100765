import React from "react";
import cx from "classnames";
import { Link } from 'react-router-dom';
import { useDelete } from "seed/gql";
import * as queries from "seed/gql/queries"
import { useTable, usePagination } from "react-table";

import styles from "styles/css/admin/List.module.css";
import Loading from 'seed/helpers/Loading';

function AdminList(props)
{
  const { clients } = props;
  const [cDelete, qDelete] = useDelete(queries.DELETE_CLIENT);

  const columns = [
    {
      Header: "Nombre",
      accessor: "name"
    },
    {
      Header: "C. de invitación",
      accessor: "promoCode"
    },
    {
      Header: "Estatus",
      accessor: "status"
    },
    {
      Header: "Opciones",
      accessor: "options"
    },
  ];

  const getStatus = (status) =>
  {
    if (status == "ACTIVE") return "Activo";
    if (status == "TO_REGISTRY") return "Sin registrar";
    return "OK";
  }

  const getColor = (status) =>
  {
    if (status == "ACTIVE") return "#64B48C0A";
    if (status == "TO_REGISTRY") return "#F0A00020";
    return "#64B48C0A";
  }

  const parseData = (clients) =>
  {
    let tempData = {};
    let data2 = [];

    clients.map(({ id, createdAt, company, partner, parentClient, promoCode }, idx) =>
    {
      let status = partner == "-" ? "TO_REGISTRY" : "ACTIVE"
      tempData = {
        id,
        name: company,
        promoCode: promoCode ? promoCode.value : "-",
        color: getColor(status),
        status: getStatus(status),
        isSub: parentClient != null
      };
      data2.push(tempData);
    });
    return data2;
  };

  let data = parseData(clients);

  const deleteClient = (id) => {
    let conf = window.prompt("¿Estás seguro de eliminar a este cliente?, se borraran TODOS los proveedores y alertas. Para continuar escribe ELIMINAR");
    if (conf != null && conf.toUpperCase() == "ELIMINAR")
      cDelete({id: id});
  }
  
  const onClickAlert = (id) =>
  {
    props.history.push(`${props.match.url}/details/${id}`);
  }

  const onClickEmail = (promoCode) =>
  {
    props.history.push(`${props.match.url}/invitation/${promoCode}`);

  }

  const renderCell = (i, cell, idx) =>
  {
    if (idx == 0)
      return (
        <td {...cell.getCellProps()}> 
        { 
          !data[i].isSub ?
            <label>{cell.render("Cell")}</label> :
            <label className={styles.sub}>&nbsp;&nbsp;&nbsp;&nbsp;{cell.render("Cell")}</label>
        }
        </td>
      )
    if (idx == 2)
      return (
        <td {...cell.getCellProps()}>
        <span style={{
          color: data[i].color.substring(0, data[i].color.length - 2),
          backgroundColor: data[i].color,
          border: `1px solid ${data[i].color.substring(0, data[i].color.length - 2) + "c5"}`
        }} >
          {cell.render("Cell")}
        </span>
      </td>
      )
    if (idx == 3)
      return (
        <td {...cell.getCellProps()}>
          {data[i].status == "Activo" ?
          <i className={cx(styles.alertIcon, "fas fa-bell")}
            onClick={(e) => onClickAlert(data[i].id)}></i>
          : 
          <i className={cx(styles.alertIcon, "fas fa-envelope-open-text")}
          onClick={(e) => onClickEmail(data[i].promoCode)}></i>}
          <i className={cx(styles.removeIcon, "fas fa-minus-circle")}
            onClick={(e) => {e.stopPropagation(); deleteClient(data[i].id);}}></i>
        </td>
      )
    else
      return (
        <td {...cell.getCellProps()}> {cell.render("Cell")}</td>
      )
  }

  return (
  <div 
    className={styles.container}
    style={{width: "100%", height: "100%"}}>
    
    <Table columns={columns} data={data} />
    { qDelete.loading ?
     <div style={{position: "absolute", top: "250px", left: "50%"}}>
      <Loading /> 
    </div> : null
    }
   
  </div>);

  function Table({ columns, data })
  {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
      usePagination
    });

    return (
      <table
        {...getTableProps()}
        className={cx("table", "table-striped", "table-hover", styles.box)}
      >
        <thead className={cx(styles.row, styles.head)}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => {
                if (idx == 1)
                  return (
                  <th {...column.getHeaderProps()}>{column.render("Header")} 
                    <Link to={`${props.match.url}/promo_info`}
                      className={styles.info}>
                      <i className={"fas fa-info-circle"}></i>
                    </Link>
                  </th>)
                return (<th {...column.getHeaderProps()}>{column.render("Header")}</th>)
              }
              )}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className={cx(styles.row, styles.content)}
        >
          {rows.map((row, i) =>
          {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {
                  row.cells.map((cell, idx) => renderCell(i, cell, idx))
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default AdminList;
