/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const SINGULARS =
{
  "alerts": "alert",
  "alert": "alert",
  "clients": "client",
  "client": "client",
  "dofPublications": "dofPublication",
  "dofPublication": "dofPublication",
  "promoCodes": "promoCode",
  "promoCode": "promoCode",
  "publications": "publication",
  "publication": "publication",
  "satPublications": "satPublication",
  "satPublication": "satPublication",
  "transactions": "transaction",
  "transaction": "transaction",
  "users": "user",
  "user": "user",
};