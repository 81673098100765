import React, { Component } from "react";
import cx from "classnames";
import styles from "styles/css/auth/recover/RecoveryMessage.module.css";
import { Link } from 'react-router-dom'


class RecoveryMessage extends Component
{
  render()
  {
    const { type } = this.props.match.params;

    return (
      <div className={styles.module}>
        <div className={styles.background}>
          <div className={styles.dots} />
          <Link to="/">
            <img
              className={styles.logo}
              src={"/resources/images/logo.png"}
              alt="Logo" />
          </Link>

          <div className={cx(styles.container, 'animated zoomIn')}>

            {type == "request" ?
              <div>
                <label className={styles.title}>Revisa tu correo para recuperar tu contraseña</label>
                <label className={styles.subtitle}><i>Nota: Si el correo no aparece en tu bandeja principal revisa tus correos no deseados</i></label>
              </div> : null}

            {type == "changed" ?
              <div>
                <label className={styles.title}>Contraseña modificada</label>
                <label className={styles.subtitle}>Ingresa nuevamente con tu nueva contraseña</label>
                <br />
                <br />
                <Link to="/login" className={cx(styles.login)}>
                  Iniciar sesión
                  </Link>
              </div> : null}

          </div>

        </div>

        <p className={cx(styles.footer)}>
          Chevez, Ruiz, Zamarripa y Cia., S.C | <Link to="/terms">Términos y condiciones</Link> | <a href="mailto:erick.rivas@chevez.com.mx">Ayuda</a>
        </p>
      </div >


    );
  }

  constructor(props)
  {
    super(props);
    this.onClickBack = this.onClickBack.bind(this);
  }

  onClickBack()
  {
    this.props.history.replace(`/login`);
  }
}

export default RecoveryMessage;