import React from 'react';
import cx from "classnames";
import styles from 'styles/css/info/PromoCode.module.css';


function Tutorial(props)
{
  return (
    <div className={styles.module}>
      <div className={cx(styles.row, "row")}>
        <div className={cx(styles.col, "col-md-12")}>
          <div className={styles.title}
            style={{ marginTop: "25px" }}>Código de invitación</div>
          <div className={styles.subtitle}>Comparte este código de invitación con los cliente para que puedan registrase en el sistema</div>
          <div className={styles.image}><i className="fas fa-envelope-open-text"></i></div>
        </div>
       
      </div>
    </div>
  );
}

export default Tutorial;
