import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from 'styles/css/info/Instructions.module.css';

function Login(props)
{
  return (
    <div className={styles.module}>
      <div className={styles.background}>
        <div className={styles.dots} />
        <Link to="/">
          <img
            className={styles.logo}
            src={"/resources/images/logo.png"}
            alt="Logo" />
        </Link>

        <div className={cx(styles.container, 'animated slideInUp')}>

          <label className={styles.title}>Llave de seguridad</label>
          <label className={styles.subtitle}>
            La llave de seguridad es un archivo para encriptar tu información y que solo tú puedas acceder a ella<br /><br />
            <i>Para utilizarla selecciona el archivo <br/>&quot;crz-llave.pem&quot; que descargaste al momento de regístrate</i>
          </label>

          <div className={styles.content}>
            <img
              src={"/resources/images/secret.png"}
              className={styles.imgSecret}
              alt="Logo" />
            <Link to="/login"
              className={styles.login}>
              Iniciar sesión
              </Link>
          </div>

        </div>

      </div>

      <p className={cx(styles.footer)}>
        Chevez, Ruiz, Zamarripa y Cia., S.C | <Link to="/terms">Términos y condiciones</Link> | <a href="mailto:erick.rivas@chevez.com.mx">Ayuda</a>
      </p>

    </div >
  );
}

export default Login;
