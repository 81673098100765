import React from "react";
import { useQuery } from "seed/gql";
import { Route, Link } from "react-router-dom";
import cx from "classnames";
import Loading from 'seed/helpers/Loading';
import List from "components/folders/List";
import Modal from 'seed/helpers/Modal';

import styles from "styles/css/folders/Folders.module.css";

const CLIENTS = `
{
  clients {
    name
    parentClient {}
  }
}
`

function Folders(props)
{
  const { path } = props.match;
  const userId = sessionStorage.getItem("id");
  const clientId = sessionStorage.getItem("clientId");
  const qClients = useQuery(CLIENTS, `admin=${userId} OR notified=${userId} OR parent_client.admin=${userId} OR parent_client.notified=${userId}`,
    {
      orderBy: "created_at"
    });

  if (qClients.loading) return <Loading />;
  if (qClients.error) return "Error";
  const { clients = [] } = qClients.data;

  const ListModal = (props) =>
    <Modal {...props}
      width={450}
      height={500}
      animation="">
      <List {...props} />
    </Modal>

  const onChangeFolder = (e) => 
  {
    const val = e.currentTarget.value;
    if (val != "_folders"){
      const clientId = parseInt(val);
      sessionStorage.setItem("clientId", clientId);
      window.location.reload();
    } else { 
      e.currentTarget.value = sessionStorage.getItem("clientId");
      props.history.push(`${path}/folders`);
    }
  }

  return (
    <div className={cx("card", styles.module, styles.box)}>
      <div className={cx("card-header", styles.header, styles.row)}>
        <div className={cx(styles.titleContainer)}>
          <span className={cx(styles.title)}>Carpetas 
            <Link to={`${props.match.url}/folders`}
                className={styles.info}>
                <i className={"fas fa-info-circle"}></i>
            </Link>
          </span>
        </div>
        <div>
          <select onChange={onChangeFolder}>
            {
              clients.map(client => 
                client.parentClient ? <option selected={client.id == clientId} value={client.id}>{client.name}</option> : <option selected={client.id == clientId} value={client.id}>{client.name}</option> 
                )
            }
            <option value="_folders">* Administrar carpetas</option>
          </select>
        </div>
      </div>
      <Route path={`${path}/folders`}
        component={ListModal} />
    </div>
  );
}

export default Folders;
