import React, { useState } from "react";
import cx from "classnames";
import { usePost } from 'seed/api';
import { Link } from 'react-router-dom';
import { Formik, Field } from "formik";
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "styles/css/auth/recover/RecoveryForm.module.css";

function RecoveryForm(props)
{
  const [state, setState] = useState({});
  const [cChange, qChange] = usePost("/users/change_password", {
    onCompleted: () => props.history.replace(`/recover_message/changed`),
    onError: error => 
    {
      setState(s => ({
        error: error.status == 404 ?
          "Correo no registrado" :
          "Ha ocurrido un error"
      }));
    }
  })

  const onSubmit = (values) =>
  {
    const { token } = props.match.params;
    if (values.password != values.password2)
      return setState(s => ({ error: "Las contraseñas no coinciden" }));
    cChange({ password: values.password, token: token });
  }

  return (
    <div className={styles.module}>
      <div className={styles.background}>
        <div className={styles.dots} />
        <Link to="/">
          <img
            className={styles.logo}
            src={"/resources/images/smp.png"}
            alt="Logo" />
        </Link>

        <img
          className={styles.crz}
          src={"/resources/images/logo.png"}
          alt="Logo" />

        <div className={cx(styles.container, 'animated zoomIn')}>

          <label className={styles.title}>Recuperar contraseña</label>

          <div className={styles.content}>

            <Formik
              onSubmit={onSubmit}
              initialValues={{ password: "", password2: "" }}
              render={props => (
                <form onSubmit={props.handleSubmit}>
                  <div className={cx("form-group")}>
                    <label for="email">Escribe tu contraseña</label>
                    <Field
                      type="password"
                      name="password"
                      className={cx("form-control", styles.input)} required
                    />
                    <label for="email" style={{ marginTop: "10px" }}>Confirma tu contraseña</label>
                    <Field
                      type="password"
                      name="password2"
                      className={cx("form-control", styles.input)} required
                    />
                  </div>
                  {state.error ?
                    <div className={styles.error + ' animated fadeIn'}><div> {state.error}</div></div> : null}
                  <div>
                    <button className={cx(styles.submit)}
                      type="submit">
                      Enviar
                      </button>
                  </div>
                </form>
              )}
            />

          </div>

        </div>

      </div>

      <p className={cx(styles.footer)}>
        Chevez, Ruiz, Zamarripa y Cia., S.C | <Link to="/terms">Términos y condiciones</Link> | <a href="mailto:erick.rivas@chevez.com.mx">Ayuda</a>
      </p>
    </div >
  );
}

export default RecoveryForm;