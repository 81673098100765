import React from "react";
import cx from "classnames";
import { useDelete } from "seed/gql";
import * as queries from "seed/gql/queries"
import { useTable, usePagination } from "react-table";
import moment from "moment";
import { shortMonths } from 'components/util/constants';

import styles from "styles/css/transactions/List.module.css";
import Loading from 'seed/helpers/Loading';


function TransactionList(props)
{
  const { transactions, showDate=false } = props;
  const [cDelete, qDelete] = useDelete(queries.DELETE_TRANSACTION);

  const columns = [
    {
      Header: "RFC",
      accessor: "rfc"
    },
    {
      Header: "Estatus",
      accessor: "status"
    },
    {
      Header: showDate ? "Fecha de registro" : "Descripción",
      accessor: showDate ? "date" : "description"
    },
    {
      Header: "",
      accessor: "options"
    }
    
  ];

  const getStatus = (situation) =>
  {
    if (situation == "DEFINITIVE") return "Definitivo";
    if (situation == "ALLEGED") return "Presunto";
    if (situation == "NO_LOCATED") return "No localizado";
    if (situation == "NO_LOCATED_REMOVED") return "Eliminado no localizado";
    if (situation == "DETRACTED") return "Desvirtuado";
    if (situation == "FAVORABLE") return "Sentencia favorable";
    return "OK";
  }

  const getColor = (situation) =>
  {
    if (situation == "DEFINITIVE") return "#ED5B7020";
    if (situation == "ALLEGED") return "#F0A00020";
    if (situation == "NO_LOCATED") return "#F0900020";
    if (situation == "NO_LOCATED_REMOVED") return "#88888810";
    if (situation == "DETRACTED") return "#88888810";
    if (situation == "FAVORABLE") return "#88888810";
    return "#64B48C0A";
  }

  const getValue = (situation) =>
  {
    if (situation == "DEFINITIVE") return 10000000;
    if (situation == "ALLEGED") return 100000;
    if (situation == "NO_LOCATED") return 10000;
    if (situation == "NO_LOCATED_REMOVED") return 1000;
    if (situation == "DETRACTED") return 100;
    if (situation == "FAVORABLE") return 10;
    return 0;
  }

  const parseData = (transactions) =>
  {
    let tempData = {};
    let data2 = [];

    transactions.map(({ id, createdAt, rfc, rfcD, alerts }, idx) =>
    {
      let month = moment(createdAt).month();
      let day = moment(createdAt).date();
      let year = moment(createdAt).year();
      let alertsSort = [...alerts];
      alertsSort = alertsSort.sort((i1, i2) => (new Date(i2.date)).getTime() - (new Date(i1.date)).getTime())
      const situation = alertsSort.length > 0 ? alertsSort[0].publication.situation : "";
      let description = alertsSort.length > 0 ? alertsSort[0].publication.name : "";
      description = description.includes("//") ? description.substring(0, description.indexOf("//")) : description
      const alertId = alertsSort.length > 0 ? alertsSort[0].id : 0;
      tempData = {
        id,
        rfc: rfcD,
        rfcE: rfc,
        alertId: alertId,
        description: description,
        date: createdAt ? `${day}.${shortMonths[month]}.${year}` : "-",
        situation: situation,
        color: getColor(situation),
        status: getStatus(situation)
      };
      data2.push(tempData);
    });
    return data2;
  };

  let data = parseData(transactions)
    .sort((t1, t2) => getValue(t2.situation) - getValue(t1.situation));

  const deleteTransaction = (id) => {
    
    cDelete({id: id});
  }
  
  const onClickRow = (alertId, rfc) =>
  {
    if (alertId != 0)
      props.history.push(`${props.match.url}/alerts/${rfc}/${alertId}`);
  }

  const renderCell = (i, cell, idx) =>
  {
    if (idx == 1)
      return (
        <td {...cell.getCellProps()}>
        <span style={{
          color: data[i].color.substring(0, data[i].color.length - 2),
          backgroundColor: data[i].color,
          border: `1px solid ${data[i].color.substring(0, data[i].color.length - 2) + "c5"}`
        }} >
          {cell.render("Cell")}
        </span>
      </td>
      )
    if (idx == 3)
      return (
        <td {...cell.getCellProps()}
          style={{textAlign: "center"}}> 
          <i className={cx(styles.removeIcon, "fas fa-minus-circle")}
            onClick={(e) => {e.stopPropagation(); deleteTransaction(data[i].id);}}></i>
        </td>
      )
    else
      return (
        <td {...cell.getCellProps()}> {cell.render("Cell")}</td>
      )
  }

  return (
  <div 
    className={styles.container}
    style={{width: "100%", height: "100%"}}>
    
    <Table columns={columns} data={data} />
    { qDelete.loading ?
     <div style={{position: "absolute", top: "250px", left: "50%"}}>
      <Loading /> 
    </div> : null
    }
   
  </div>);

  function Table({ columns, data })
  {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
      usePagination
    });

    return (
      <table
        {...getTableProps()}
        className={cx("table", "table-striped", "table-hover", styles.box)}
      >
        <thead className={cx(styles.row, styles.head)}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className={cx(styles.row, styles.content)}
        >
          {rows.map((row, i) =>
          {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
                style={{cursor: "pointer"}}
                onClick={() => onClickRow(data[i].alertId, data[i].rfc)}>
                {
                  row.cells.map((cell, idx) => renderCell(i, cell, idx))
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default TransactionList;
