import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import styles from "styles/css/App.module.css";
import Home from "components/Home";
import HomeAdmin from "components/HomeAdmin";
import Login from 'components/auth/Login';
import LoginAdmin from 'components/auth/LoginAdmin';
import LoginAdminAuth from 'components/auth/LoginAdminAuth';
import Registry from 'components/auth/Registry';
import RegistryDone from 'components/auth/RegistryDone';
import RegistryDoneNotif from 'components/auth/RegistryDoneNotif';
import Terms from 'components/info/Terms';
import Instructions from 'components/info/Instructions';
import Recovery from 'components/auth/recover/Recovery';
import RecoveryForm from 'components/auth/recover/RecoveryForm';
import RecoveryMessage from 'components/auth/recover/RecoveryMessage';

import Logout from 'components/auth/Logout';


function App(props)
{
  return (
    <div className={styles.module}>
      <Router>
        <Switch>
          <Route path={`/app`} component={Home} />
          <Route path={`/login`} component={Login} />
          <Route path={`/portal`} component={HomeAdmin} />
          <Route path={`/socios/auth`} component={LoginAdminAuth} />
          <Route path={[`/socios`, `/socios.`]} component={LoginAdmin} />
          <Route path={`/registry/done`} component={RegistryDone} />
          <Route path={`/registry/done_notif`} component={RegistryDoneNotif} />
          <Route path={`/registry`} component={Registry} />
          <Route path={`/terms`} component={Terms} />
          <Route path={`/instructions`} component={Instructions} />
          <Route path={`/recover_password/:token`} component={RecoveryForm} />
          <Route path={`/recover_message/:type`} component={RecoveryMessage} />
          <Route path={`/recover`} component={Recovery} />
          <Route path={`/logout`} component={Logout} />
          <Redirect to={`/app`} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
