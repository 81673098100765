import React from "react";
import cx from "classnames";
import { useDetail, useQuery } from "seed/gql";
import moment from "moment";
import Loading from 'seed/helpers/Loading';

import styles from "styles/css/alerts/Details.module.css";
import TransactionList from "components/transactions/List";
import { months } from 'components/util/constants';
import { hasht, decrypt } from "components/util/security";

const ALERT = `
{
  alert {
    publication {
      rfc
      situation
      type
      name
      dofPublications {  
      }
      satPublications {
        satPostDate
        dofPostDate
        satDetractedPostDate
        dofDetractedPostDate
        satDefinitivePostDate
        dofDefinitivePostDate
        satFavorablePostDate
        dofFavorablePostDate
      }
    }
  }
}
`

const TRANSACTIONS = `
{
  transactions {
    id
    date
    createdAt
    rfc
    alerts {
      date
      publication {
        id
        type
        name
        situation
      }
    }
  }
}
`

function AlertDetails(props)
{
  const userId = sessionStorage.getItem("id");
  const { rfc, alert_id } = props.match.params;
  const hashtRfc = hasht(rfc);

  const qAlert = useDetail(ALERT, alert_id);
  const qTransactions = useQuery(TRANSACTIONS, `rfc_hash=${hashtRfc} AND (client.admin=${userId} OR client.notified=${userId}) `)
  if (qAlert.loading || qTransactions.loading) return <Loading />;
  if (qAlert.error || qAlert.error) return "Error";

  const { alert } = qAlert.data;
  let { transactions = [] } = qTransactions.data;

  transactions = transactions
    .map(transaction =>
    {
      let tempTransaction = Object.assign({}, transaction)
      let rfcD = "-"
      try {
        rfcD = decrypt(transaction.rfc);
      } catch (e) { }
      tempTransaction.rfcD = rfcD
      return tempTransaction;
    });

  const details = alert.publication.type == "SAT" ? alert.publication.satPublications[0] : alert.publication.dofPublications[0];
  const tempSit = alert.publication.situation;
  const tempProp =
    tempSit === "DEFINITIVE"
      ? {
        icon: "fas fa-exclamation-triangle",
        color: "rgba(239, 71, 111, 1)",
        bkgColor: "rgba(239, 71, 111, 0.1)"
      }
      : tempSit === "ALLEGED"
        ? {
          icon: "fas fa-exclamation-circle",
          color: "rgba(255, 183, 77, 1)",
          bkgColor: "rgba(255, 183, 77, 0.1)"
        }
        : tempSit === "NO_LOCATED"
          ? {
            icon: "fas fa-house-damage",
            color: "rgba(255, 150, 77, 1)",
            bkgColor: "rgba(255, 150, 77, 0.1)"
          }
          : {
            icon: "fas fa-check-circle",
            color: "rgba(150, 150, 150, 1)",
            bkgColor: "rgba(150, 150, 150, 0.1)"
          };

  const parseDate = (date) =>
  {
    const original = new Date(date);
    const d = new Date(original.getTime() + (new Date()).getTimezoneOffset() * 60000);
    return date ? `${moment(d).date()} de ${months[moment(d).month()]} ${moment(d).year()}` : "-"
  }

  const renderSat = (details) =>
    <table>
      <tbody>
        <tr>
          <td>Fuente de información:</td>
          <td>DOF art. {tempSit != "NO_LOCATED" && tempSit != "NO_LOCATED_REMOVED" ? "69-B" : "69"} Código Fiscal de la Federación</td>
        </tr>
        {tempSit != "NO_LOCATED" && tempSit != "NO_LOCATED_REMOVED" ?
          <tr>
            <td>Publicación - Presunto:</td>
            <td>{parseDate(details.satPostDate)}</td>
          </tr> : null}
        {tempSit == "DETRACTED" ?
          <tr>
            <td>Publicación - Desvirtuado:</td>
            <td>{parseDate(details.satDetractedDocDate)}</td>
          </tr> : null}
        {tempSit == "DEFINITIVE" ?
          <tr>
            <td>Publicación - Definitivo:</td>
            <td>{parseDate(details.satDefinitivePostDate)}</td>
          </tr> : null}
        {tempSit == "FAVORABLE" ?
          <tr>
            <td>Publicación - Favorable:</td>
            <td>{parseDate(details.satFavorablePostDate)}</td>
          </tr> : null}
        {tempSit == "NO_LOCATED" ?
          <tr>
            <td>Publicación - No localizado:</td>
            <td>{parseDate(details.satPostDate)}</td>
          </tr> : null}
        {tempSit == "NO_LOCATED_REMOVED" ?
          <tr>
            <td>Publicación - Eliminado de Relación No localizado:</td>
            <td>{parseDate(details.satPostDate)}</td>
          </tr> : null}
      </tbody>
    </table>

  return (
    <div className={cx(styles.paper)}>
      <div className={cx(styles.box)}>
        <div
          className={cx(
            styles.row,
            styles.header,
            styles.mainHeader,
            styles.flexContainer
          )}>
          <div className={cx(styles.flexCol)}>
            <h5
              id="simple-modal-title"
              className={cx(styles.title, styles.modalTitle)}>
              Detalles
            </h5>
          </div>
        </div>
        <div
          className={cx(styles.row, styles.header, styles.flexContainer)}
          style={{
            backgroundColor: tempProp.bkgColor,
            paddingLeft: "12px",
            paddingRight: "12px"
          }}>
          <div>
            <i className={tempProp.icon} style={{ color: tempProp.color }}></i>
            &nbsp;&nbsp;{alert.publication.rfc}
          </div>
          <div>{alert.publication.name}</div>
        </div>
        <br />
        <div className={cx(styles.row, styles.header, styles.details)}>
          <div className={cx(styles.titleContainer)}>
            <h5 className={cx(styles.title)}>PUBLICACIONES</h5>
          </div>
          <div style={{ marginTop: "5px" }}>
            {alert.publication.type == "SAT" ?
              renderSat(details) : null}
          </div>
          <br />
          <div className={styles.titleContainer}>
            <h5 className={styles.title}>Registros</h5>
          </div>
          <br />
        </div>
        <div className={cx(styles.row, styles.content)}>
          <TransactionList 
            transactions={transactions} 
            showDate={true} />
        </div>
      </div>
    </div>
  );
}

export default AlertDetails;
