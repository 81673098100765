import React from 'react';
import cx from 'classnames';
import { API_URL } from 'settings';
import styles from 'styles/css/transactions/misc/Massive.module.css';

function UploadForm(props)
{

  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>Carga masiva</h4>
      </div>

      <div className={styles.paper, styles.form}>
        <div className={styles.notice}>Instrucciones para .NET framework <a href={`${API_URL.substring(0, API_URL.length-4)}/static/net.zip`}>Descargar</a></div>
        <div className={styles.notice}>Instrucciones para Java <a href={`${API_URL.substring(0, API_URL.length-4)}/static/java.zip`}>Descargar</a></div>
        <div className={styles.notice}>Instrucciones para Python <a href={`${API_URL.substring(0, API_URL.length-4)}/static/python.zip`}>Descargar</a></div>
        <div className={styles.notice}>Instrucciones para Javascript <a href={`${API_URL.substring(0, API_URL.length-4)}/static/js.zip`}>Descargar</a></div>
        <div className={styles.notice}>Instrucciones para REST API <a href={`${API_URL.substring(0, API_URL.length-4)}/static/rest.zip`}>Descargar</a></div>
      </div>
    </div>
  );
}

export default UploadForm;
