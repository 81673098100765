const getQueryDate = (date, def, isStart) =>
{

  if (date == null) date = def
  date = new Date(date.getFullYear(), isStart ? date.getMonth(): date.getMonth()+1, 1, 0,0,0)
  return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
} 

const getPublishQuery = (situation, filterAdv) =>
{
  let tableName = "satPostDate"
  if (situation == "DETRACTED") tableName = "satDetractedDocDate"
  if (situation == "DEFINITIVE") tableName = "satDefinitivePostDate"
  if (situation == "FAVORABLE") tableName = "satFavorablePostDate"
  
  return `
    (alerts.publication.situation = ${situation} AND
    alerts.publication.satPublications.${tableName} >= ${getQueryDate(filterAdv[2], new Date("2000/01/01"), true) } AND
    alerts.publication.satPublications.${tableName} <= ${getQueryDate(filterAdv[3], new Date(), false) })`
}

const getFilteredQuery = (clientId, filterTerm, filterAdv) =>
{
  let query = `client=${clientId} `;
  if (filterTerm == "THREAT") query += "AND (alerts.publication.situation=DEFINITIVE OR alerts.publication.situation=ALLEGED OR alerts.publication.situation=NO_LOCATED)"
  if (filterTerm == "BLACKLIST") query += "AND (alerts.publication.situation=DEFINITIVE)"
  if (filterTerm == "GREYLIST") query += "AND (alerts.publication.situation=ALLEGED)"
  if (filterTerm == "GREYLIST") query += "AND (alerts.publication.situation=ALLEGED)"
  if (filterTerm == "NO_LOCATED") query += "AND (alerts.publication.situation=NO_LOCATED)"
  if (filterTerm == "OTHER") query += "AND (alerts.publication.situation=DETRACTED OR alerts.publication.situation=FAVORABLE OR alerts.publication.situation=NO_LOCATED_REMOVED)"
  if (filterTerm == "ALL") query += ""
  try{
    let advFilter = "";
    advFilter += `
      createdAt >= ${getQueryDate(filterAdv[0], new Date("2000/01/01"), true) } AND 
      createdAt <= ${getQueryDate(filterAdv[1], new Date(), false) }`
    if (filterTerm != "ALL")
      advFilter += ` AND (${getPublishQuery("DEFINITIVE", filterAdv)} OR ${getPublishQuery("ALLEGED", filterAdv)} OR ${getPublishQuery("NO_LOCATED", filterAdv)} OR ${getPublishQuery("DETRACTED", filterAdv)} OR ${getPublishQuery("FAVORABLE", filterAdv)} OR ${getPublishQuery("NO_LOCATED_REMOVED", filterAdv)}) `
    query += ` AND (${advFilter})`;
  }catch(e){}
  
  query = query.replace(/\n/g, "");
  return query;
} 

export { getFilteredQuery };