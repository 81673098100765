import React from 'react';
import cx from "classnames";
import styles from 'styles/css/info/Tutorial.module.css';


function Tutorial(props)
{
  return (
    <div className={styles.module}>
      <div className={cx(styles.row, "row")}>
        <div className={cx(styles.col, "col-md-4")}>
          <div className={styles.title}
            style={{ marginTop: "15px" }}>Paso 1</div>
          <div className={styles.subtitle}
            style={{ marginTop: "25px" }}>Carga los datos de tus proveedores de forma segura.</div>
          <div className={styles.image}
            style={{ marginTop: "25px" }}><i className="fas fa-upload"></i></div>
        </div>
        <div className={cx(styles.col, "col-md-4")}>
          <div className={styles.title}
            style={{ marginTop: "15px" }}>Paso 2</div>
          <div className={styles.subtitle}>El sistema dará seguimiento a tus proveedores en caso de aparecer listados en el Art. 69 & 69-B del CFF.</div>
          <div className={styles.image}><i className="fas fa-code-branch"></i></div>
        </div>
        <div className={cx(styles.col, "col-md-4")}>
          <div className={styles.title}
            style={{ marginTop: "15px" }}>Paso 3</div>
          <div className={styles.subtitle}>En caso de identificar a un proveedor listado, se enviará inmediatamente una alerta por correo electrónico.</div>
          <div className={styles.image}><i className="fas fa-exclamation-triangle"></i></div>
        </div>
      </div>
    </div>
  );
}

export default Tutorial;
