import React from 'react';
import cx from 'classnames';
import { useDetail, useSet } from 'seed/gql'
import { usePost } from 'seed/api';
import * as queries from 'seed/gql/queries'
import { Formik, Form, Field } from 'formik';
import { remove } from "lodash";
import Loading from 'seed/helpers/Loading';
import styles from 'styles/css/transactions/Notifications.module.css';

const CLIENT = `
{
  client {
    admin {
      id
      email
    }
    notified {
      id
      email
    }
  }
}
`

function Notifications(props)
{
  const clientId = sessionStorage.getItem("parentId");
  const qClient = useDetail(CLIENT, clientId);
  const { client = {} } = qClient.data;
  const [cSetClient] = useSet(queries.SET_CLIENT, {
    onCompleted: () => qClient.refetch()
  })
  const [cPostUser] = usePost("/users/registry", {
    onCompleted: data =>
      cSetClient({
        id: clientId,
        notified: [...client.notified, { id: data.id }]
      })
  })

  if (qClient.loading) return <Loading />;
  if (qClient.error) return "Error";


  const deleteItem = (id) =>
  {
    let notifiedTemp = [...client.notified];
    notifiedTemp = remove(notifiedTemp,
      (follower) => follower.id !== id)
    cSetClient({ id: clientId, notified: notifiedTemp });
  }

  const onSubmit = (values) =>
    cPostUser({ email: values.email })

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>Editar notificaciones</h4>
      </div>

      <div className={cx(styles.paper, styles.form, styles.box)}>
        <div className={cx(styles.row, styles.header)}>
          <Formik
            initialValues={{ name: "" }}
            onSubmit={onSubmit}
            render={(props) => (
              <Form className={cx(styles.verticalBox)}>
                <div className={cx(styles.strech)}>
                  <Field type="email" placeholder="Escribe un correo para notificar" name="email" className={cx(styles.input)} required />
                </div>
                <div className={cx(styles.fixed)}>
                  <button type="submit" className={cx(styles.btn, styles.btnGreen, styles.addButton)}>
                    <i className="fas fa-plus"></i> Agregar
                  </button>
                </div>
              </Form>
            )}
          />
        </div>
        <div className={cx(styles.row, styles.content)}>
          {
            client.notified.map((not, i) =>
              <div key={not.id}
                className={cx(styles.listItem)}>
                <div>{not.email}</div>
                <button
                  className={cx(styles.deleteButton)}
                  onClick={() => deleteItem(not.id)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>)
          }
        </div>
      </div>
    </div>
  );
}

export default Notifications;
