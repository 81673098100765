import React, { useState } from "react";
import $ from "jquery";
import cx from "classnames";
import { Route, Link } from "react-router-dom";
import { usePagination, useCount } from "seed/gql";
import { decrypt } from 'components/util/security';
import List from "components/transactions/List";
import Upload from "components/transactions/Upload";
import Notifications from "components/transactions/Notifications";
import Filters from 'components/transactions/misc/Filters';
import Massive from "components/transactions/misc/Massive";
import Loading from 'seed/helpers/Loading';
import Modal from 'seed/helpers/Modal';

import { getFilteredQuery } from 'components/transactions/Transactions.lutil';

import stylesM from "styles/css/seed/styles/Modal.module.css";
import styles from "styles/css/transactions/Transactions.module.css";

const TRANSACTIONS = `
{
  transactionPagination {
    totalPages
    transactions {
      id
      date
      createdAt
      rfc
      alerts {
        date
        publication {
          id
          type
          name
          situation
        }
      }
    }
  }
}
`

function Transactions(props)
{
  const { url, path } = props.match;
  const [showFilters, setShowFilters] = useState(false);
  const [filterAdv, setFilterAdv] = useState([ null, null, null, null]);
  const [filterTerm, setFilterTerm] = useState("THREAT");
  const [encrypted, setEncrypted] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const clientId = sessionStorage.getItem("clientId");
  const qTransactionAllCount = useCount("transaction", `client=${clientId}`);
  const query = getFilteredQuery(clientId, filterTerm, filterAdv);
  const qTransactions = usePagination(TRANSACTIONS, pageNum, filterTerm != "ALL" ? 42 : 32, query, 
    {
      orderBy: "-id",
      onCompleted: () => setEncrypted(null)
    });

  if (qTransactions.loading || qTransactionAllCount.loading) return <Loading />;
  if (qTransactions.error || qTransactionAllCount.error) return "Error";

  let { transactions = [], totalPages = 0 } = qTransactions.data.transactionPagination;
  const transactionAllCount = qTransactionAllCount.data.transactionCount ? qTransactionAllCount.data.transactionCount.count : 0;

  let transactionsMapped = [...transactions]
  transactionsMapped = transactionsMapped.filter(transaction =>
    {
      const tempAlerts = [...transaction.alerts]
      const alerts = tempAlerts.sort((i1, i2) => (new Date(i2.date)).getTime() - (new Date(i1.date)).getTime())
      const situation = alerts.length > 0 ? alerts[0].publication.situation : "";
      if (filterTerm == "THREAT") return situation == "DEFINITIVE" || situation == "ALLEGED" || situation == "NO_LOCATED";
      if (filterTerm == "BLACKLIST") return situation == "DEFINITIVE";
      if (filterTerm == "GREYLIST") return situation == "ALLEGED";
      if (filterTerm == "NO_LOCATED") return situation == "NO_LOCATED";
      if (filterTerm == "OTHER") return situation == "DETRACTED" || situation == "FAVORABLE" || situation == "NO_LOCATED_REMOVED";
      if (filterTerm == "ALL") return true;
    })
    .map(transaction =>
    {
      let tempTransaction = Object.assign({}, transaction)
      let rfcD = "-"
      try {
        if (encrypted != null)
          rfcD = encrypted[transaction.id]
      } catch (e) { }
      tempTransaction.rfcD = rfcD
      return tempTransaction;
    })
    
  const decryptL = (ini, end) =>
  {
    let res = encrypted ? encrypted : {};
    transactionsMapped
      .map((transaction, idx) =>
      {
        if (idx >= ini && idx <= end) {
          let rfcD = "-"
          try {
            rfcD = decrypt(transaction.rfc);
          } catch (e) { }
          res[transaction.id] = rfcD;
        }
      });
    setEncrypted(res)
  }

  if (encrypted == null) {
    setTimeout(function ()
    {
      decryptL(0, 10)
    }, 0)
    setTimeout(function ()
    {
      decryptL(0, 100)
    }, 1000)
  }

  const onChangeHandler = event =>
  {
    setFilterTerm(event.target.value);
    setPageNum(1);
  }

  const onClickPage = i =>
    setPageNum(i);
  
  const openUpload = () =>
    setTimeout(
      () => $("." + stylesM.module + " div").first().css("pointer-events", "none"), 1000);
  
  const FiltersModal = (props) =>
    <Modal {...props}
      width={340}
      height={470}
      animation=""
      onClose={() => setShowFilters(false)}>
      <Filters {...props}
        filterAdv={filterAdv}
        setFilterAdv={setFilterAdv}
        setShowFilters={setShowFilters} />
    </Modal>

  const UploadModal = (props) =>
    <Modal {...props}
      width={480}
      height={450}
      animation="">
      <Upload {...props} />
    </Modal>

  const MassiveModal = (props) =>
    <Modal {...props}
      width={480}
      height={500}
      animation="">
      <Massive {...props} />
    </Modal>

  const NotificationsModal = (props) =>
    <Modal {...props}
      width={480}
      height={500}
      animation="">
      <Notifications {...props} />
    </Modal>

  let pages = [];
  const pageCount = totalPages;
  let pageIni = pageNum - 7 >= 1 ? pageNum - 7 : 1;

  if (pageIni > 1)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(pageIni - 1)}
        style={{ background: "#fff" }}>{"<"}</div>)

  for (let i = pageIni; i <= pageIni + 13 && i <= pageCount; i++)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(i)}
        style={{ background: pageNum == i ? "#f5f5f5" : "#fff" }}>{i}</div>)

  if (pageCount > pageIni + 13)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(pageIni + 14)}
        style={{ background: "#fff" }}>{">"}</div>)

  let hasAdvFilter = false;
  for (let fil of filterAdv)
      if (fil != null) hasAdvFilter = true;

  return (
    <div className={cx("card", styles.heighthundred, styles.box)}>
      <div className={cx("card-header", styles.header, styles.row)}>
        <div className={cx(styles.titleContainer)}>
          <p className={cx(styles.title)}>Proveedores</p>
        </div>
        <div className={cx(styles.options)}>
          <div className={cx(styles.dropdown)}>
            <label htmlFor="filter" className={cx(styles.lbl)}>
              Filtrar
            </label>
            <select
              className={cx(styles.input)}
              name="filter"
              value={filterTerm}
              id="filter"
              onChange={onChangeHandler}>
              <option value="THREAT">Alertas</option>
              <option value="BLACKLIST">Definitivos</option>
              <option value="GREYLIST">Presuntos</option>
              <option value="NO_LOCATED">No Localizado</option>
              <option value="OTHER">Otro</option>
              <option value="ALL">Todos</option>
            </select>
            <label className={cx(styles.moreFilters)}
              onClick={() => setShowFilters(true)}>
                {hasAdvFilter ? <i className={cx(styles.filterAdvIcon, "fas fa-filter")}></i> : null} 
                Filtro avanzado</label>
          </div>
          <div className={cx(styles.button, styles.add)}>
            <Link
              to={`${url}/upload`}
              className={cx(
                "btn",
                "btn-success",
                styles.upload,
                styles.btn,
                styles.btnSuccess
              )}
              onClick={() => openUpload()}>
              <i className="fas fa-upload"></i>Cargar datos
            </Link>
          </div>
        </div>
      </div>
      <div className={cx(styles.row, styles.content, styles.listContainer)}>
        <List
          transactions={transactionsMapped}
          filterTerm={filterTerm}
          match={props.match}
          history={props.history}
        />
        <div className={styles.legend}>{
          transactionAllCount == 0 ?
            <div>
              <Link to={`${url}/upload`}><i className="fas fa-upload"></i></Link><br /><span style={{ fontStyle: "normal", color: "#a0a0a0" }}>Carga tus datos para monitorear proveedores listados en el Art. 69 & 69-B del CFF</span></div> :
            transactionsMapped.length == 0 && filterTerm != "ALL" ?
              <div><i class="fas fa-check-circle"></i><br />No se han detectado proveedores listados en el Art. 69 o 69-B del CFF<br /><span style={{ color: "#aaa" }}>En caso de identificar alguno te notificaremos inmediatamente por correo electrónico</span></div> :
              null
        }</div>
      </div>
      {pages.length > 1 ?
        <div className={styles.pages}>
          {pages}
        </div> : null
      }

      <Route path={`${path}/upload`}
        component={UploadModal} />
      <Route path={`${path}/massive`}
        component={MassiveModal} />
      <Route path={`${path}/notifications`}
        component={NotificationsModal} />
      {
        showFilters ? <FiltersModal /> : null
      }
    </div>
  );
}

export default Transactions;
