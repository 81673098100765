import React, { useState } from 'react';
import { usePost, useGet } from 'seed/api';
import { Link, Route } from 'react-router-dom';
import cx from 'classnames';
import Modal from 'seed/helpers/Modal'
import RegistryContact from 'components/auth/RegistryContact';
import TermsValidation from 'components/info/TermsValidation';
import styles from 'styles/css/auth/Registry.module.css';
import { genKeys } from 'components/util/security'


function Registry(props)
{
  const {path, url} = props.match;
  const [state, setState] = useState(0);
  const [showVerif, setShowVerif] = useState(false);
  const [body, setBody] = useState({});
  const qPartners = useGet("/users/partners")
  const [cRegistry, qRegistry] = usePost("/clients/registry", {
    onCompleted: data =>
    {
      const type = data.type;
      if (type == "new") 
        props.history.replace('/registry/done');
      else {
        sessionStorage.setItem("tempAdmin", data.admin);
        props.history.replace('/registry/done_notif');
      }
    },
    onError: error =>
    {
      if (error.status == 404)
        setState({ error: 'Código de invitación inválido' });
      else if (error.status == 406)
        setState({ error: 'Usuario ya registrado, prueba recuperar tu contraseña' });
      else if (error.status == 409)
        setState({ error: 'Cliente ya registrado' });
      else if (error.status == 410)
        setState({ error: 'Código de invitación ya registrado' });
      else
        setState({ error: 'Ha ocurrido un error, prueba de nuevo' });
      setShowVerif(false)
    }
  });

  let partners = qPartners.data ? qPartners.data : [];
  partners = partners
    .map(u => ({ ...u, completeName: u.firstName + " " + u.lastName }))
    .sort((u1, u2) => u1.completeName.localeCompare(u2.completeName))

  const onSubmit = e =>
  {
    e.preventDefault();

    let name = e.target.name.value;
    let lastName = e.target.lastName.value;
    let company = e.target.company.value;
    let rfc = e.target.rfc.value.trim().toUpperCase();
    let phone = e.target.phone.value;
    let email = e.target.email.value;
    let password = e.target.password.value;
    let password2 = e.target.password2.value;
    let partner = e.target.partner.value;
    let promoCode = e.target.promoCode.value;

    let keys = genKeys();
    let publicKey = keys.publicKey;
    let privateKey = keys.privateKey;
    sessionStorage.setItem("tempPrivateKey", privateKey);

    if (password != password2)
      return setState({ error: 'Las contraseñas no coinciden' });

    setBody({
      name: name,
      last_name: lastName,
      company: company,
      rfc: rfc,
      phone: phone,
      email: email,
      password: password,
      partner: partner,
      promo_code: promoCode,
      public_key: publicKey
    })
    setShowVerif(true);
  }

  const ValidationModal =
    <Modal animation="" width={500} height={500} onClose={
      () => setShowVerif(false)}>
      <TermsValidation
        body={body}
        cRegistry={cRegistry}
        qRegistry={qRegistry}
        setShowVerif={setShowVerif}
      />
    </Modal>

const ContactModal = (props) =>
  <Modal animation="" width={450} height={500} onClose={
    () => setShowVerif(false)}>
    <RegistryContact {...props}
      rfc={body.rfc} />
  </Modal>

  return (
    <div className={styles.module}>

      <div className={styles.background}>
        <div className={styles.dots} />
        <Link to="/">
          <img
            className={styles.logo}
            src={"/resources/images/smp.png"}
            alt="Logo" />
        </Link>

        <img
          className={styles.crz}
          src={"/resources/images/logo.png"}
          alt="Logo" />

        <div className={cx(styles.container, 'animated slideInUp')}>

          <label className={styles.title}>Registro</label>
          <form onSubmit={onSubmit}>

            <div className="row">

              <div className="col-md-6 col-sm-12">
                <label className={styles.emailLbl}>Nombre del titular</label><br />
                <input className={styles.email}
                  name="name"
                  type="text"
                  required />
              </div>

              <div className="col-md-6 col-sm-12">
                <label className={styles.emailLbl}>Apellido del titular</label><br />
                <input className={styles.email}
                  name="lastName"
                  type="text"
                  required />
              </div>

            </div>
            
            <div className="row">
            
              <div className="col-md-8 col-sm-12">
                <label className={styles.emailLbl}>Empresa</label><br />
                <input className={styles.email}
                  name="company"
                  type="text"
                  required />
              </div>

              <div className="col-md-4 col-sm-12">
                <label className={styles.emailLbl}>RFC</label><br />
                <input className={styles.email}
                  name="rfc"
                  type="text"
                  required />
              </div>

            </div>

            <label className={styles.emailLbl}>Teléfono</label><br />
            <input className={styles.email}
              name="phone"
              type="phone"
              required /><br />

            <hr />

            <label className={styles.emailLbl}>Correo electrónico</label><br />
            <input className={styles.email}
              name="email"
              type="email"
              required /><br />

            <label className={styles.passwordLbl}>Contraseña</label><br />
            <input className={styles.password}
              name="password"
              type="password"
              required /><br />

            <label className={styles.passwordLbl}>Repetir Contraseña</label><br />
            <input className={styles.password}
              name="password2"
              type="password"
              required /><br />

            <hr />

            <label className={styles.passwordLbl}>Nombre del socio</label><br />
            <select name="partner" className={styles.partner} required>
              <option value="">Selecciona un socio</option>
              {
                partners.map(u => <option value={u.completeName}>{u.completeName} </option>)
              }
            </select><br />

            <label className={styles.passwordLbl}>Código de invitación</label><br />
            <input className={styles.email}
              name="promoCode"
              type="text"
              placeholder="AAA000"
              required /><br />

            {state.error ?
              <div className={styles.error}>
                {state.error}
                {state.error.startsWith("Cliente") ? 
                  <span>
                  ,&nbsp;revisa si ya fue dada de alta la cuenta o&nbsp;
                  <Link to={`${url}/contact`} style={{textDecoration: "underline"}}>contacta al titular registrado</Link>
                  </span> : null
                }
              </div> : null}
            <button type="submit" className={cx(styles.submit)}>Registrar cuenta</button>

          </form>

          {showVerif ? ValidationModal : null}
          {body.rfc ?
            <Route path={`${path}/contact`} component={ContactModal}/> : null}
        </div>
      </div>

      <p className={cx(styles.footer)}>
        Chevez, Ruiz, Zamarripa y Cia., S.C | <Link to="/terms">Términos y condiciones</Link> | <a href="mailto:erick.rivas@chevez.com.mx">Ayuda</a>
      </p>

    </div>
  );
}

export default Registry;
