import React, { useState } from "react";
import cx from "classnames";
import { Route, Link } from "react-router-dom";
import { usePagination } from "seed/gql";
import List from "components/admin/List";
import Details from "./Details";
import AddClient from "./AddClient";
import Invitation from "./InvitationForm"
import PromoCode from "components/info/PromoCode"
import Modal from 'seed/helpers/Modal';
import Loading from 'seed/helpers/Loading';
import styles from "styles/css/admin/Admin.module.css";

const CLIENTS = `
{
  clientPagination {
    totalPages 
    clients {
      name
      partner
      promoCode {
        value
      }
      parentClient {
        name
      }
    }
  }
}
`

function Admin(props)
{
  const { url, path } = props.match;
  const [pageNum, setPageNum] = useState(1);
  const userId = sessionStorage.getItem("id");
  const query = `assigned=${userId} OR promoCode.user=${userId} OR parentClient.assigned=${userId} OR parentClient.promoCode.user=${userId}`;
  const qClients = usePagination(CLIENTS,  pageNum, 42, query);

  
  if (qClients.loading) return <Loading />;
  if (qClients.error) return "Error";


  let { clients = [], totalPages = 0 } = qClients.data.clientPagination;
  clients = clients.map(client => {
    let clientTemp = {...client}
    return Object.assign(clientTemp, {company: client.parentClient ? client.parentClient.name + " > " + client.name : client.name})
  });

  clients = clients
    .sort((c1, c2) => c1.company.localeCompare(c2.company))
    .sort((c1, c2) => {
      let id1 = c1.parentClient ? c1.parentClient.id : c1.id
      let id2 = c2.parentClient ? c2.parentClient.id : c2.id
      return id2 - id1;
    });
  

  const DetailsModal = (props) =>
    <Modal {...props}
      width={600}
      height={600}>
      <Details {...props} />
    </Modal>

  const AddClientModal = (props) =>
      <Modal {...props}
        width={380}
        height={250}>
        <AddClient {...props} />
      </Modal>

  const PromoInfoModal = (props) =>
    <Modal {...props}
      width={365}
      height={285}>
      <PromoCode {...props} />
    </Modal>

  const InvitationModal = (props) =>
    <Modal {...props}
      width={580}
      height={570}>
      <Invitation {...props} />
    </Modal>


  const onClickPage = i =>
    setPageNum(i);

  let pages = [];
  const pageCount = totalPages;
  let pageIni = pageNum - 7 >= 1 ? pageNum - 7 : 1;

  if (pageIni > 1)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(pageIni - 1)}
        style={{ background: "#fff" }}>{"<"}</div>)

  for (let i = pageIni; i <= pageIni + 13 && i <= pageCount; i++)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(i)}
        style={{ background: pageNum == i ? "#f5f5f5" : "#fff" }}>{i}</div>)

  if (pageCount > pageIni + 13)
    pages.push(
      <div
        className={styles.page}
        onClick={() => onClickPage(pageIni + 14)}
        style={{ background: "#fff" }}>{">"}</div>)

  return (
    <div className={cx("card", styles.heighthundred, styles.box)}>
      <div className={cx("card-header", styles.header, styles.row)}>
        <div className={cx(styles.titleContainer)}>
          <p className={cx(styles.title)}>Clientes</p>
        </div>
        <div className={cx(styles.button, styles.add)}>
            <Link
              to={`${url}/add_code`}
              className={cx(
                "btn",
                "btn-success",
                styles.upload,
                styles.btn,
                styles.btnSuccess
              )}>
              <i className="fas fa-plus"></i>Nueva invitación
            </Link>
          </div>
      </div>
      <div className={cx(styles.row, styles.content, styles.listContainer)}>
      <List
          clients={clients}
          match={props.match}
          history={props.history}
        />
        <div className={styles.legend}>
          {
          pageCount == 0 ?
            <div>
              <Link to={`${url}/add_code`}><i className="fas fa-plus"></i></Link><br />
                <span style={{ fontStyle: "normal", color: "#a0a0a0" }}>Da de alta invitaciones para agregar clientes</span>
            </div> : null
          }
      </div>
      </div>
      {pages.length > 1 ?
        <div className={styles.pages}>
          {pages}
        </div> : null
      }
      <Route path={`${path}/add_code`}
        component={AddClientModal} />
       <Route path={`${path}/promo_info`}
        component={PromoInfoModal} />
      <Route path={`${path}/details/:client_id(\\d+)`}
        component={DetailsModal} />
      <Route path={`${path}/invitation/:promo_code`}
        component={InvitationModal} />
    </div>
  );
}

export default Admin;
