import React from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api';
import Loading from 'seed/helpers/Loading'
import styles from 'styles/css/auth/RegistryContact.module.css';

function RegistryContact(props)
{
  const [cSend, qSend] = usePost("/clients/contact_duplicated", {
    onCompleted: data => {
      alert("Mensaje enviado");
      props.onClose();
    },
    onError: error => 
      alert("Ha ocurrido un error, prueba de nuevo")
  })

  
  const onSubmit = (e) =>
  {
    e.preventDefault();
    const rfc = props.rfc;
    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;
    cSend({name: name, email: email, message: message, rfc: rfc})
  }

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>Contactar titular</h4>
      </div>

      <div className={(styles.paper, styles.form)}>
      <form onSubmit={onSubmit}>
         
        <input name="name"
          type="text"
          placeholder="Nombre"
          className={cx(styles.fil, styles.fileFil)}/>

        <input name="email"
          type="text"
          placeholder="Correo"
          className={cx(styles.fil, styles.fileFil)}/>

        <textarea name="message"
          type="text"
          rows={6}
          placeholder="Mensaje"
          className={cx(styles.fil, styles.fileFil)}>
        </textarea>

          <button type="submit"
            className={cx(styles.btn, styles.btnGreen, styles.fullBtn)}>
            Enviar mensaje</button>
          </form>
          {qSend.loading ? <Loading /> : null}
      </div>
    </div>
  );
}

export default RegistryContact;
