import React, { useState, useRef } from 'react';
import { usePost } from 'seed/api';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from 'styles/css/auth/Login.module.css';

function Login(props)
{
  const [state, setState] = useState(0);
  const fileRef = useRef(null);
  const [cLogin] = usePost("/auth/login", {
    onCompleted: data =>
    {
      sessionStorage.setItem('token', data.key);
      sessionStorage.setItem('id', data.user);
      sessionStorage.setItem('role', 'client');
      props.history.replace('/');
    },
    onError: () => setState({ error: 'Usuario o contraseña inválidos' }),
    includeAuth: false
  });

  const onSubmit = e =>
  {
    e.preventDefault();
    const privateKey = sessionStorage.getItem("privateKey")
    if (privateKey == null)
      return setState({ error: 'Agrega tu llave de seguridad (crz-key.pem)' })
    if (!privateKey.startsWith("-----BEGIN") ||
      !privateKey.endsWith("PRIVATE KEY-----"))
      return setState({ error: 'Llave de seguridad inválida' })
    let email = e.target.email.value;
    let password = e.target.password.value;
    cLogin({ email: email, password: password });
  }

  const handleInputFileChange = e =>
  {
    e.preventDefault();
    let file = fileRef.current.files[0];
    let re = /(\.pem)$/i;
    if (file && re.exec(file.name)) {
      let reader = new FileReader();
      reader.onload = event =>
      {
        let key = event.target.result;
        sessionStorage.setItem("privateKey", key);
      }
      reader.readAsText(file);
    } else {
      alert("Failed to load key")
    }
  }

  return (
    <div className={styles.module}>

      <div className={styles.background}>
        <div className={styles.dots} />
        <Link to="/">
          <img
            className={styles.logo}
            src={"/resources/images/smp.png"}
            alt="Logo" />
        </Link>

        <img
          className={styles.crz}
          src={"/resources/images/logo.png"}
          alt="Logo" />


        <div className={cx(styles.container, 'animated zoomIn')}>

          <label className={styles.title}>Iniciar sesión</label>
          <form onSubmit={onSubmit}>
            <label className={styles.emailLbl}>Correo</label><br />
            <input className={styles.email}
              name="email"
              type="email"
              required /><br />
            <label className={styles.passwordLbl}>Contraseña</label><br />
            <input className={styles.password}
              name="password"
              type="password"
              required /><br />
            <label className={styles.passwordLbl}>Llave de seguridad <span>(crz-llave.pem)</span>
              <Link to="/instructions"
                className={styles.info}>
                <i className={"fas fa-info-circle"}></i>
              </Link>
            </label><br />
            <input name="key"
              type="file"
              ref={fileRef}
              placeholder="Seleccione un archivo"
              accept=".pem"
              className={styles.securityToken}
              onChange={handleInputFileChange}
              required />

            <Link to="/recover"
              className={styles.recover}>
              Recuperar contraseña
            </Link>

            {state.error ?
              <div className={styles.error}>{state.error}</div> : null}
            <button type="submit" className={styles.submit}>Iniciar sesión</button>

          </form>

        </div>

        <Link to="/registry"
          className={cx(styles.registry, "animated zoomIn")}>Registro</Link>


      </div>

      <p className={cx(styles.footer)}>
        Chevez, Ruiz, Zamarripa y Cia., S.C | <Link to="/terms">Términos y condiciones</Link> | <a href="mailto:erick.rivas@chevez.com.mx">Ayuda</a>
      </p>

    </div>
  );
}

export default Login;
