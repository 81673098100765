import crypto from "crypto-browserify";
import nodeRSA from "node-rsa";

export const encrypt = (message) =>
{
    var publicKey = sessionStorage.getItem("publicKey")
    var buffer = Buffer.from(message.length > 20 ? message.substring(0, 20) : message);
    var encryptedMessage = crypto.publicEncrypt(publicKey, buffer);
    return encryptedMessage.toString("base64");
};

export const decrypt = (encryptedMessage) =>
{
    var privateKey = sessionStorage.getItem("privateKey")
    var buffer = Buffer.from(encryptedMessage, "base64");
    var decrypted = crypto.privateDecrypt(privateKey, buffer);
    return decrypted.toString("utf8");
};

export const hasht = (message) =>
    crypto.createHash('sha256').update(message).digest('hex');

export const genKeys = () =>
{
    const key = new nodeRSA({ b: 512 });
    return {
        publicKey: key.exportKey('pkcs8-public'),
        privateKey: key.exportKey('pkcs8-pem')
    };
}