import React from 'react';
import queryString from 'query-string';

function LoginAdminAuth(props)
{
  const { status, token, id } = queryString.parse(props.location.search) 
  if (status == "OK"){
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('id', id);
    sessionStorage.setItem('role', 'admin');
    window.location.replace("/portal");
  }else return <div>Ha ocurrido un error iniciando sesión</div>;
}

export default LoginAdminAuth;
