import React, { useEffect, useState } from "react";
import { useDetail, useQuery, useSet } from 'seed/gql';
import { useGetCall } from 'seed/api';
import cx from "classnames";
import * as queries from 'seed/gql/queries'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alerts from "components/alerts/Alerts";
import Folders from "components/folders/Folders";
import Transactions from "components/transactions/Transactions";
import Search from 'components/search/Search'
import Toolbar from 'components/navigation/Toolbar';
import Tutorial from 'components/info/Tutorial'
import Modal from 'seed/helpers/Modal';
import styles from "styles/css/Home.module.css";

const CLIENTS = `
{
  clients {
    publicKey
    hasTutorial
  }
}
`

const CLIENT = `
{
  client {
    name
    updateStatus
  }
}
`

function Home(props)
{
  const [clientLoaded, setClientLoaded] = useState(false);
  const [search, setSearch] = useState("");
  const [showTutorial, setShowTutorial] = useState(true);
  const userId = sessionStorage.getItem("id");

  const [isAuth, setIsAuth] = useState(false);
  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: (data) => {
      setIsAuth(true);
    },
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/login");
    }
  })  

  const [cSetClient] = useSet(queries.SET_CLIENT);

  const qClients = useQuery(CLIENTS, `parent_client IS NULL AND (admin=${userId} OR notified=${userId})`, {
    onCompleted: data =>
    {
      if (data.clients && data.clients.length > 0) 
      {
        if (sessionStorage.getItem("parentId") == null)
        {
          sessionStorage.setItem("parentId", data.clients[0].id);
          sessionStorage.setItem("clientId", data.clients[0].id);
          sessionStorage.setItem("publicKey", data.clients[0].publicKey);
        }
        setClientLoaded(true);
      }
    }
  });

  const qClient = useDetail(CLIENT, sessionStorage.getItem("clientId"))

  const { clients = [] } = qClients.data;
  const { client = {} } = qClient.data;

  const closeTutorial = () =>
  {
    const clientId = parseInt(sessionStorage.getItem('clientId'));
    cSetClient({ id: clientId, hasTutorial: true })
    setShowTutorial(false)
  }

  const closeNotice = () => 
  {
    const clientId = parseInt(sessionStorage.getItem('clientId'));
    cSetClient({ id: clientId, updateStatus: "" })
    qClient.refetch();
  }

  const TutorialModal = (props) =>
    <Modal {...props}
      width={715}
      height={270}
      onClose={closeTutorial}
      animation="slideInUp">
      <Tutorial {...props} />
    </Modal>

useEffect(() =>
{
  const userId = sessionStorage.getItem('id');
  const role = sessionStorage.getItem('role')
  if (userId == null)
    return props.history.replace(`/login`);
  if (role == "admin")
    return props.history.replace(`/portal`)
  callAuth();
}, []);

 
  if (!isAuth) return null;
  
  if (!clientLoaded)
    return <CircularProgress className={styles.loading} size={60} />;

  return (
    <div className={cx(styles.module)}>
      <div className={cx(styles.box)}>
        <div>
          <Toolbar {...props}
            search={search}
            setSearch={setSearch}
            className={cx(styles.row, styles.head)} />
        </div>
        <div className={cx(styles.notice)}>
        {
          client.updateStatus == "COMPLETED" ?
            <div className={cx(styles.completed)}>Carga completada <span onClick={closeNotice}>x Cerrar mensaje</span></div> : null
        }
        {
          client.updateStatus == "PROCESSING" ?
            <div className={cx(styles.processing)}>Procesando datos, puede tomar unos minutos. Refresca la pantalla para actualizar el status<span onClick={closeNotice}>x Cerrar mensaje</span></div> : null
        }
        {
          client.updateStatus == "ERROR" ?
            <div className={cx(styles.error)}>Ha ocurrido un error, intenta más tarde<span onClick={closeNotice}>x Cerrar mensaje</span></div> : null
        }
        </div>
        <div className={cx(styles.home, "row")}>
          <div className={cx(styles.sidepanel, "col-md-4", "col-sm-12")}>
            <div className={styles.alerts}><Alerts {...props} /></div>
            <div className={styles.folders}><Folders {...props} /></div>
          </div>
          <div className={cx(styles.conent, "col-md-8", "col-sm-12")}>
            {search == "" ? 
              <Transactions {...props}/> :
              <Search {...props}
                search={search}
                setSearch={setSearch}
              />
            }
          </div>
        </div>
      </div>
      {clients.length > 0 &&
        clients[0].hasTutorial == false &&
        showTutorial == true ? <TutorialModal /> : null}
    </div>
  );
}

export default Home;