import React, { useState } from 'react';
import cx from 'classnames';
import { useQuery, useDelete } from 'seed/gql';
import * as queries from "seed/gql/queries"
import Loading from 'seed/helpers/Loading';
import Form from "components/folders/Form";
import Modal from 'seed/helpers/Modal';
import styles from 'styles/css/folders/List.module.css';

const CLIENTS = `
{
  clients {
    name
    rfc
    parentClient {}
  }
}
`

function FolderList(props)
{
  const userId = sessionStorage.getItem("id");
  const [formData, setFormData] = useState({});
  const [cDelete] = useDelete(queries.DELETE_CLIENT);
  const qClients = useQuery(CLIENTS, `admin=${userId} OR notified=${userId} OR parent_client.admin=${userId} OR parent_client.notified=${userId}`,
    {
      orderBy: "created_at"
    });

  if (qClients.loading) return <Loading />;
  if (qClients.error) return "Error";
  const { clients = [] } = qClients.data;


  const deleteItem = (id) =>
  {
    let conf = window.prompt("Al eliminar esta carpeta se borraran TODOS los proveedores y alertas.\nPara continuar escribe ELIMINAR");
    if (conf != null && conf.toUpperCase() == "ELIMINAR")
      cDelete({id: id});
  }

  const editItem = (id, name, rfc) =>
    setFormData({
      show: true,
      id: id,
      name: name,
      rfc: rfc
    })

  const newItem = () =>
    setFormData({
      show: true
    })

  const FormModal = (props) =>
    <Modal {...props}
      width={485}
      height={300}
      animation=""
      onClose={() => setFormData({})}>
      <Form 
        clientId={formData.id}
        name={formData.name}
        rfc={formData.rfc}
        {...props} />
    </Modal>

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>Administrar carpetas</h4>
      </div>

      <div className={cx(styles.paper, styles.form, styles.box)}>

        <i className={styles.info}>Maneja diferentes carpetas para agrupar fácilmente la información de tus proveedores</i>
        
        <div className={cx(styles.row, styles.content)}>
          {
            clients.map((client, i) =>
              <div key={client.id}
                className={cx(styles.listItem, styles.unClicked)}>
                <div>
                  <i className="fas fa-folder"></i>
                  <label>
                    {client.name}
                    {
                      client.parentClient == null ?
                      <span className={styles.main}>(Principal)</span>:null
                    }
                    <span className={styles.sub}><br/>{client.rfc}</span>
                  </label>
                </div>
                
                <div>
                  <button
                    className={cx(styles.actionButton)}
                    onClick={() => editItem(client.id, client.name, client.rfc)}>
                    <i className="fas fa-edit"></i>
                  </button>
                  {
                  client.parentClient ?
                    <button
                      className={cx(styles.actionButton)}
                      onClick={() => deleteItem(client.id)}>
                      <i className="fas fa-times"></i>
                    </button> : null 
                  }
                </div>
                
              </div>)
          }
          <div className={cx(styles.listItem, styles.action)}
            onClick={newItem}>
            <div>
              <i className="fas fa-folder-plus"></i>
              Crear carpeta
              </div>
          </div>
        </div>
      </div>
      { formData.show ? <FormModal /> : null }
    </div>
  );
}

export default FolderList;
