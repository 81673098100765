import React, { useState } from 'react';
import cx from 'classnames';
import styles from 'styles/css/info/TermsValidation.module.css';
import Loading from 'seed/helpers/Loading';

function TermsValidation(props)
{
  const [accept, setAccept] = useState(false);

  const onClickCheckbox = (e) =>
  {
    setAccept(e.target.checked)
  }

  const onSubmit = (e) =>
  {
    if (!accept)
      return alert("Para registrarte acepta términos y condiciones");
    props.cRegistry(props.body)
  }

  return (
    <div className={styles.module}>

      <div className={styles.background}>

        <div className={cx(styles.container)}>


          <label className={styles.title}>AVISO DE PRIVACIDAD</label><br />
          <br/>En cumplimiento a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo &quot;la Ley&quot;), nos permitimos informarle lo siguiente:
          <br/>
          <br/><b>1. RESPONSABLE DE LOS DATOS PERSONALES</b>
          <br/>
          <br/>Chevez, Ruiz, Zamarripa y Cía., S.C. (en lo sucesivo &quot;CRZ&quot;), señalando para efectos del presente Aviso de Privacidad el domicilio ubicado en Vasco de Quiroga número 2121, 4° piso, Colonia Peña Blanca Santa Fe, C.P. 01210, Ciudad de México, será el responsable de los datos personales que le sean proporcionados por las personas a quienes correspondan (en lo sucesivo el “Titular”).
          <br/>
          <br/><b>2. DATOS PERSONALES QUE SE SOLICITAN DEL TITULAR</b>
          <br/>
          <br/>Para las finalidades señaladas en el presente Aviso de Privacidad, CRZ podrá recabar todos o alguno de los Datos Personales del Titular como son: nombre completo, teléfono fijo, teléfono celular, correo electrónico, Registro Federal de Contribuyentes (RFC), Clave Única de Registro de Población (CURP), cargo y actividad económica.
          <br/>
          <br/>CRZ podrá obtener los Datos Personales de alguna de las siguientes formas: i) cuando el Titular los proporcione personal o directamente; ii) cuando CRZ los obtenga de forma indirecta, al ser proporcionados por quien cuenta con el consentimiento del Titular; iii) cuando el Titular utilice cualquier sitio de internet de o asociado con CRZ; iv) cuando el Titular utilice o se registre como usuario de los Servicios en Línea (según dicho término se define adelante en los Términos y Condiciones del Servicio); y/o v) cuando se obtengan por CRZ a través de las fuentes permitidas por la legislación.
          <br/>
          <br/>Las personas que utilicen cualquier sitio de internet de o asociado con CRZ o aquellas personas que utilicen o se registren como usuarios de los Servicios en Línea (Usuarios) podrán proporcionar, además de sus Datos Personales, información de personas morales y datos de contacto de personas físicas que previamente les hayan otorgado su consentimiento para utilizar dicha información (Terceros). Por lo tanto, para los efectos del presente Aviso de Privacidad, los Usuarios que, en los términos a que se refiere el presente párrafo, proporcionen información de Terceros manifiestan, bajo protesta de decir verdad, que cuentan con el pleno consentimiento de dichas personas, para todos los efectos y responsabilidades contractuales y extracontractuales que pudieran derivar del uso de dicha información por parte de CRZ, del Encargado (según se define dicho termino adelante) o el personal de ambos. En caso de ser requeridos por CRZ, los Usuarios se obligan a proporcionar, en un plazo máximo de tres días hábiles, los elementos probatorios necesarios y suficientes para acreditar que cuentan con el consentimiento de referencia.
          <br/>
          <br/>Lo anterior, con independencia de la notificación que CRZ podrá hacer a los Terceros respecto del Aviso de Privacidad al primer o posterior contacto que tenga con estos últimos.
          <br/>
          <br/>El Usuario manifiesta, bajo protesta de decir verdad, que los Datos Personales y/o la información de Terceros, que en este acto proporciona, son veraces y completos, obligándose a notificar, inmediatamente, a CRZ cualquier modificación a los mismos para mantener la información actualizada.
          <br/>
          <br/><b>3. FINALIDADES PARA LAS QUE SE RECABAN LOS DATOS PERSONALES</b>
          <br/>
          <br/>La información de Terceros y los Datos Personales recolectados por CRZ tendrá el uso que se describe a continuación:
          <br/>
          <br/>i) Para la prestación del Servicio en Línea o cualquier otro Servicio que preste CRZ al Titular, al Usuario o a cualquier Tercero (en adelante los Servicios); 
          <br/>
          <br/>ii) Para el cumplimiento de las obligaciones derivadas de la relación jurídica que CRZ mantenga con el Titular, el Usuario o cualquier Tercero;
          <br/>
          <br/>iii) Para la identificación del Titular, del Usuario o del Tercero;
          <br/>
          <br/>iv) Para la administración de los Servicios;
          <br/>
          <br/>v) Para el análisis de los Servicios;
          <br/>
          <br/>vi) Para la actualización de datos del Titular, del Usuario o del Tercero;
          <br/>
          <br/>vii) Para contactar al Titular, al Usuario o al Tercero, en su carácter de beneficiarios, para dar cumplimiento a los Servicios; 
          <br/>
          <br/>viii) Para el cumplimiento de la legislación aplicable; y
          <br/>
          <br/>ix) Para el envío a los Terceros, los Usuarios y/o Titulares de la siguiente información:
          <br/>
          <br/>    • Comunicados que contengan el ofrecimiento y promoción de servicios.
          <br/>    • Boletines, invitaciones e información relacionada con los servicios que presta CRZ.
          <br/>    • Para solicitar evaluaciones y/o encuestas relacionadas directa o indirectamente con los servicios que presta CRZ.
          <br/>
          <br/>En el evento que un Tercero, un Usuario y/o el Titular no estén de acuerdo en la utilización de su información o Datos Personales para las finalidades indicadas en los incisos anteriores deberán manifestar su oposición, de acuerdo a lo dispuesto en la sección 5 del presente Aviso de Privacidad, ya que, en caso de no manifestar su oposición se entenderá que otorgan su consentimiento a CRZ para el uso de su información y/o Datos Personales con las finalidades establecidas en el presente Aviso de Privacidad.
          <br/>
          <br/><b>4. TEMPORALIDAD, REMISIONES Y TRANSFERENCIAS.</b>
          <br/>
          <br/>La temporalidad del manejo de los Datos Personales del Titular dependerá de la relación jurídica que se mantenga con CRZ, así como de las obligaciones exigidas por la legislación vigente, las autoridades competentes y las políticas internas de CRZ. En caso de que la relación sea por tiempo indeterminado, la utilización de la información y/o los Datos Personales podrá mantenerse hasta que el Titular solicite la suspensión de dicho tratamiento o, en su caso, hasta que CRZ decida eliminarlos de manera unilateral. 
          <br/>
          <br/>La información y los Datos Personales que sean entregados a CRZ serán resguardados, conservados y protegidos por este último, de acuerdo a lo dispuesto por la legislación vigente y las políticas internas de CRZ para efecto de mantener la confidencialidad de dicha información.
          <br/>
          <br/>De acuerdo a lo dispuesto por la Ley, CRZ dará acceso a los Datos Personales del Titular (a través de remisiones) a prestadores de servicios, o socios de negocios de CRZ (Encargado), que tengan una relación jurídica con éste último y que, derivado de dicha relación jurídica, necesiten conocer o dar tratamiento a la información y asuman el compromiso de mantenerla bajo un estricto orden de confidencialidad y seguridad, reconociendo además conocer los términos y condiciones del presente Aviso de Privacidad y comprometiéndose a dar cumplimiento a los mismos.
          <br/>
          <br/>Por lo anterior, si el Tercero, el Usuario y/o el Titular no manifiestan su oposición, a través de alguno de los medios establecidos en la sección 5 del presente Aviso de Privacidad, para que sus Datos Personales sean transferidos, se entenderá que han otorgado su consentimiento para ello.
          <br/>
          <br/>En caso de que la información de Terceros y/o del Usuario o los Datos Personales resguardados sean requeridos por una autoridad de cualquier índole o deban ser entregados a esta última, de acuerdo a la legislación vigente, éstos se pondrán a su disposición, en estricto cumplimiento a la Ley y sin necesidad de recabar el consentimiento del Titular, del Tercero o del Usuario, de acuerdo a lo dispuesto en el artículo 37 de dicho ordenamiento legal.
          <br/>
          <br/><b>5. DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN, OPOSICIÓN, REVOCACIÓN Y LIMITACIÓN DE LOS DATOS PERSONALES.</b>
          <br/>
          <br/>Los Titulares de la información tendrán derecho a solicitar el acceso, rectificación, revocación, cancelación u oposición así como limitar el uso o divulgación de sus datos, mediante:
          <br/>
          <br/>a.- Solicitud escrita dirigida y entregada a Pedro Fernando Luna Moreno de la sociedad denominada Chevez, Ruiz, Zamarripa y Cía., S.C., al siguiente domicilio: Vasco de Quiroga número 2121, 4° piso, Colonia Peña Blanca Santa Fe, C.P. 01210, Ciudad de México, de las 10:00 a las 17:00 horas, en días hábiles; o
          <br/>
          <br/>b.- Solicitud formulada al correo electrónico: privacidad@chevez.com.mx, obteniendo el correspondiente acuse de recibo.
          <br/>
          <br/>CRZ dará respuesta a las solicitudes del Titular, siempre y cuando no se actualice alguna de las excepciones contenidas en la Ley, y el solicitante cumpla con lo dispuesto en el artículo 29 de dicho ordenamiento legal, que establece los requisitos que debe contener las solicitudes presentadas por los Titulares.
          <br/>
          <br/>Para que CRZ dé trámite a la solicitud de un Titular referente al acceso, rectificación, revocación, cancelación, oposición y/o limitación del uso o divulgación de sus datos, este último deberá adjuntar a su solicitud la siguiente información y documentación:
          <br/>
          <br/>i. Nombre del Titular y domicilio u otro medio para que CRZ le comunique la respuesta a su solicitud.
          <br/>
          <br/>ii. Los documentos con los que acredite su identidad o su personalidad en caso de la representación legal del Titular (identificación oficial del Titular o del representante legal y poder de este último).
          <br/>
          <br/>iii. La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer el derecho de acceso, rectificación, revocación, cancelación, oposición, limitación del uso o divulgación, así como cualquier otro dato o documento que pueda facilitar la localización de los Datos Personales.
          <br/>
          <br/>CRZ dará respuesta a la solicitud de un Titular referente al acceso, rectificación, revocación, cancelación, oposición y/o limitación del uso o divulgación de sus datos dentro de los plazos establecidos en la Ley, es decir, dentro de los veinte (20) días siguientes contados a partir de que reciba la solicitud del Titular, plazo que podrá ser ampliado por CRZ de acuerdo a lo dispuesto por el artículo 32 de la Ley.
          <br/>
          <br/>La obligación de acceso a la información se dará por cumplida por CRZ cuando, en caso de que sea solicitado por el Titular, se ponga a disposición de este último sus Datos Personales en el domicilio de CRZ durante el plazo de 15 (quince) días hábiles siguientes a que se le comunique al Titular la respuesta a su solicitud o bien a través de alguna de las formas establecidas en el artículo 33 de la Ley, mismas que en la respuesta a su solicitud se le notificarán al Titular.
          <br/>
          <br/><b>6. MODIFICACIONES AL AVISO DE PRIVACIDAD</b>
          <br/>
          <br/>En el supuesto, de que CRZ requiera usar su información o sus Datos Personales con fines distintos a los pactados o convenidos al tenor de la relación jurídica que se tiene con el Tercero, el Usuario y/o con el Titular o bien, requiera de éstos información o Datos Personales distintos a los señalados en el presente Aviso de Privacidad, se les notificará en forma escrita, telefónica, electrónica, o por cualquier medio óptico, sonoro, visual u otro que la tecnología permita, ahora o en lo futuro, explicando los nuevos usos que pretenda darle a dicha información a fin de obtener su consentimiento en términos de la Ley. Con el presente Aviso de Privacidad, los Titulares de la información quedan debidamente informados de los datos que se recabaron de ellos y con qué fines, así como del hecho de que el Aviso de Privacidad podrá en todo momento ser consultado en la página de internet de CRZ www.chevez.com
          <br/>
          <br/>CRZ se reserva el derecho a modificar el presente Aviso de Privacidad para adaptarlo a novedades legislativas o jurisprudenciales, así como a prácticas comerciales. En dichos supuestos, se anunciará en la página de internet www.chevez.com los cambios de referencia.
          <br/>
          <br/>El presente Aviso de Privacidad, así como el manejo en general de la Información y/o los Datos Personales que haga CRZ, se rige por la legislación vigente y aplicable en los Estados Unidos Mexicanos, cualquier controversia que se suscite con motivo de su aplicación deberá ventilarse ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) y, de ser el caso, se someterá a la jurisdicción de los tribunales federales con sede en la Ciudad de México.
          <br/>
          <br/>
          <br/>
          <label className={styles.title}>TÉRMINOS Y CONDICIONES DE USO</label><br />
          <br/><b>1. SERVICIO EN LÍNEA</b>
          <br/>
          <br/>CRZ monitorea, da seguimiento y recaba, periódicamente (Sistema de Monitoreo de Proveedores), la información contenida en las publicaciones que realiza el Servicio de Administración Tributaria (SAT) en su sitio de Internet y/o en el Diario Oficial de la Federación (DOF), en términos de lo dispuesto por los artículos 69 y 69-B del Código Fiscal de la Federación, con la finalidad de conocer los nombres, denominaciones y/o razón social, así como el RFC de las personas físicas y morales que se enlistan en los términos de dichos preceptos legales (Listados de Contribuyentes). El Sistema de Monitoreo de Proveedores recabará información referente a proveedores consistente en el RFC.
          <br/>
          <br/>El Sistema de Monitoreo de Proveedores funciona a través de una sincronización automatizada de contenido del listado de contribuyentes del SAT y el DOF incluidos en los Listados de Contribuyentes. CRZ no se hace responsable por cambios en formatos, estructura o disponibilidad que pueda corresponder o generar fallas en la sincronización del Sistema de Monitoreo de Proveedores y sus consecuentes procesos.
          <br/>
          <br/>La periodicidad con que se actualiza el Sistema de Monitoreo de Proveedores puede o no coincidir con la periodicidad con que se publican los Listados de Contribuyentes. La información recabada por el Sistema Monitoreo de Proveedores puede o no coincidir exactamente con los Listados de Contribuyentes. 
          <br/>
          <br/>Una vez concluido el proceso de registro a que se refiere la sección 2 de los presentes Términos y Condiciones, el Usuario podrá incluir, en un archivo encriptado que permanecerá irreconocible para CRZ, el Encargado y su personal, al cual sólo se podrá acceder por el Usuario mediante el uso de la Llave de Seguridad (según se define dicho término adelante). El Sistema de Monitoreo de Proveedores mediante la sincronización automatizada del Listado de Contribuyentes del SAT, del DOF y del Contenido de Proveedores (según dicho término también se define adelante), comparará el RFC de los Proveedores con la finalidad de conocer si éstos se encuentran incluidos en el Listado de Contribuyentes, de acuerdo con el numeral 3.2 de los Presentes Términos y Condiciones, y generará los resultados de la sincronización con la finalidad de que el Usuario los consulte en el Sistema de Monitoreo de Proveedores y/o recibir y emitir tanto al Usuario como a CRZ las notificaciones o alertas referidas en el numeral 4.2 siguiente.
          <br/>
          <br/>CRZ no garantiza de manera alguna que la información contenida en el Sistema de Monitoreo de Proveedores carezca de errores, sea completa, se encuentre totalmente actualizada o contenga una solución para el Usuario, así como tampoco libera a éste de las obligaciones y cargas a que se refieren los artículos 69 y 69-B del Código Fiscal de la Federación y demás disposiciones legales aplicables, por lo que no sustituye ninguno de los procedimientos relacionados con dicho régimen jurídico y que sean obligación del Usuario o de Terceros.
          <br/>
          <br/><b>2. REGISTRO Y USO DE LA CUENTA</b>
          <br/>
          <br/>Para acceder al Servicio en Línea, el Usuario deberá registrarse y obtener una cuenta en el Sistema de Monitoreo de Proveedores creando un Nombre de Usuario y Contraseña. Es responsabilidad del Usuario asegurarse de que su contraseña sea confidencial y segura. 
          <br/>
          <br/>Una vez registrado el Usuario, el Sistema de Seguimiento generará un archivo informático, personal e intransferible (Llave de Seguridad), que permitirá al Usuario, mediante el uso de su Nombre de Usuario y su Contraseña, consultar el Sistema de Monitoreo de Proveedores. Es responsabilidad del Usuario asegurarse de que su Llave de Seguridad sea almacenada de manera confidencial y segura ya que sin ésta no será posible ingresar al Sistema de Monitoreo de Proveedores, en cuyo caso deberá volver a iniciar el registro y volver a generar el archivo encriptado que contenga la información de sus proveedores.
          <br/>
          <br/>Al registrarse, el Usuario acepta que es responsable de todas las actividades que se realicen en su nombre y librera expresamente a CRZ de cualquier responsabilidad por la consulta al Sistema de Monitoreo de Proveedores o cualquier afectación, presente o futura, que pudiera derivar del uso de la información consultada. 
          <br/>
          <br/>Toda comunicación y acceso que se realice utilizando el Nombre de Usuario, la Contraseña y la Llave de Seguridad de un Usuario, se entenderá realizada por el Usuario de que se trate, quien, confirma que tiene derecho y facultades para tomar decisiones y emitir instrucciones asociadas con el Servicio en Línea.
          <br/>
          <br/>2.1 Uso de cuenta no autorizado. El Usuario es responsable de notificar a CRZ si tiene conocimiento de algún uso o acceso no autorizado a su cuenta. CRZ no será responsable de ninguna afectación, pérdida, daños, perjuicios, responsabilidad, gastos u honorarios de abogados en los que pueda incurrir como resultado de que otra persona use su Nombre de Usuario, Contraseña y/o Llave de Seguridad, ya sea con o sin su conocimiento y / o autorización, y sin importar si ha notificado a CRZ o no de dicho uso no autorizado.
          <br/>
          <br/><b>3. CONTENIDO DE USUARIO</b>
          <br/>
          <br/>3.1 Contenido de Usuario: Al momento de crear una cuenta en el Sistema de Monitoreo de Proveedores, CRZ solicitará al Usuario proveer los siguientes datos: Nombre Completo, Nombre de empresa, RFC de la empresa teléfono fijo, teléfono celular, correo electrónico, Nombre de Socio y Código de invitación.
          <br/>
          <br/>3.2 Contenido de Proveedores: Para el funcionamiento del Sistema de Monitoreo de Proveedores, CRZ solicitará al Usuario proveer el RFC de sus proveedores. Dicha información será encriptada mediante el uso de la Llave de Seguridad y podrá ser consultada únicamente empleando dicha Llave de Seguridad. Cabe destacar que CRZ no tendrá acceso a la Llave de Seguridad, por lo que la información de dichos proveedores se mantendrá irreconocible para CRZ, el Encargado o cualquier personal de los mismos, con excepción de las notificaciones o alertas que el Sistema de Monitoreo de Proveedores genere y que están referidas en el numeral 4.2 siguiente.
          <br/>
          <br/>CRZ no se hace responsable por fallos o cualquiera otro tipo de errores, en la transcripciones del RFC de los Proveedores que el Usuario suministre al Sistema de Monitoreo de Proveedores.
          <br/>
          <br/><b>4. USO DEL SISTEMA DE SEGUIMIENTO</b>
          <br/>
          <br/>4.1 Alta de información: Para generar el análisis del Listado de Contribuyentes ubicados en los supuestos de los artículos 69 y 69-B del Código Fiscal de la Federación y poder consultar el Sistema de Monitoreo de Proveedores, se solicitará al Usuario el RFC de sus proveedores. Para el alta de dicha información, CRZ no se hace responsable por datos incompletos, corruptos, incorrectos o inconsistentes con las instrucciones proporcionadas por las plantillas, instrucciones del sistema o documentos de guía brindados al Usuario. 
          <br/>
          <br/>4.2 Notificaciones o Alertas: El Sistema de Monitoreo de Proveedores enviará notificaciones o alertas por correo electrónico u otros medios, al Usuario, a CRZ, y a Terceros que el Usuario designe cuando la información de sus proveedores, proporcionada en los términos del numeral 3.2 anterior, coincida con la información del Sistema de Monitoreo de Proveedores o los Listados de Contribuyentes. Para el manejo de notificaciones, el Sistema de Monitoreo de Proveedores solicitará direcciones de correo electrónico de destinatarios adicionales a las del Usuario y a las de CRZ a las que el Usuario desee que reciban alertas del Sistema de Monitoreo de Proveedores. No obstante, CRZ no asegura ni garantiza de forma alguna la actualización de la información del Sistema de Monitoreo de Proveedores, ni la funcionalidad y contenido de las notificaciones o alertas.
          <br/>
          <br/>CRZ no se hace responsable por fallos en el envío, direcciones de correo electrónico incorrectas o bloqueos generados por proveedores de correo electrónico.
          <br/>
          <br/><b>5. PRIVACIDAD</b>
          <br/>
          <br/>Para obtener información sobre cómo se recopila, usa y transfiere la información del Usuario o sobre el Usuario, se deberá consultar el Aviso de Privacidad incorporado en estos Términos y Condiciones.
          <br/>
          <br/><b>6. PROPIEDAD INTELECTUAL</b>
          <br/>
          <br/>El Sistema de Monitoreo de Proveedores es propiedad de CRZ, quien detenta todos los derechos de autor del mismo y sus actualizaciones. El Usuario, por el simple uso del Sistema de Monitoreo de Proveedores, reconoce y acepta los derechos de propiedad intelectual de CRZ y se obliga a respetarlos y, en su caso, sacar en paz y a salvo a CRZ y/o a las personas relacionadas con CRZ que pudieran verse afectadas por el incorrecto uso del Sistema de Monitoreo de Proveedores.
          <br/>
          <br/>CRZ no otorga licencia alguna al Usuario ni a Terceros, respecto del Sistema de Monitoreo de Proveedores ni con motivo del uso autorizado del mismo, por lo que el Usuario no podrá copiarlo, reproducirlo, almacenarlo ni transmitirlo a Terceros. CRZ podrá suspender o negar al Usuario, en cualquier momento y sin causa, el uso del Sistema de Monitoreo de Proveedores, sin necesidad de notificación previa.
          <br/>
          <br/><b>7. JURISDICCIÓN</b>
          <br/>
          <br/>Cualquier controversia que se derive de la prestación del Servicio en Línea o del uso del Sistema de Monitoreo de Proveedores, las notificaciones o alertas, se resolverá conforme a las Leyes aplicables en la República Mexicana y se someterá a la jurisdicción de los tribunales federales con sede en la Ciudad de México.
          <br/>
          <br/><b>8. CONSENTIMIENTO</b>
          <br/>
          <br/>Mediante el proceso de registro, así como el uso del Sistema de Monitoreo de Proveedores el Usuario manifiesta su aceptación lisa y llana de todos los términos establecidos en el Aviso de Privacidad y los Términos y Condiciones.
          <br/>
          <br/>
          <i>Última actualización 18 de mayo de 2020</i>


        </div>

        <div className={cx(styles.checkContainer)}>
          <input type="checkbox" name="accept"
            onChange={onClickCheckbox}></input> He leído y acepto términos y condiciones
        </div>

        <button type="button" className={cx(styles.submit)}
          onClick={onSubmit}
          style={{
            backgroundColor: accept ? "#1D5157" : "#e0e0e0",
            color: accept ? "#fff" : "#888"
          }}>
          Registrar cuenta</button>

        {props.qRegistry.loading ?
          <div style={{ position: "absolute", width: "100%", height: "100%", top: "0px", left: "0px" }}>
            <Loading />
          </div> : null}


      </div>


    </div>
  );
}

export default TermsValidation;
