import React, {useState} from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api';
import { Formik, Form, Field } from 'formik';
import styles from 'styles/css/folders/Form.module.css';


function FolderForm(props)
{
  const {clientId = 0, name = "", rfc = ""} = props;
  const [errorMessage, setErrorMessage] = useState(null)
  const [cSetClient] = usePost("/clients/update_subclient", {
    onCompleted: () => 
      window.location.reload(),
    onError: error => {
      if (error.status == 409)
        setErrorMessage('RFC ya registrado en tu lista de carpetas');
      else
        setErrorMessage('Ha ocurrido un error, prueba de nuevo');
    }
  })
  const [cPostClient] = usePost("/clients/registry_subclient", {
    onCompleted: data => 
      window.location.reload(),
    onError: error => {
      if (error.status == 409)
        setErrorMessage('RFC ya registrado en tu lista de carpetas');
      else
        setErrorMessage('Ha ocurrido un error, prueba de nuevo');
    }
  })

  const onSubmit = (values) =>
  {
    const parentId = sessionStorage.getItem("parentId")
    const userId = sessionStorage.getItem("id");
    const name = values.name;
    const rfc = values.rfc;
    if (clientId == 0)
      cPostClient({name: name, rfc: rfc, parent_id: parentId, user_id: parseInt(userId)})
    else cSetClient({client_id: clientId, name: name, rfc: rfc, user_id: parseInt(userId)})
  } 

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>{clientId == 0 ? "Crear carpeta" : "Editar carpeta"}</h4>
      </div>

      <div className={cx(styles.paper, styles.form, styles.box)}>
        <div className={cx(styles.row, styles.header)}>
          <Formik
            initialValues={{ name: name, rfc: rfc }}
            onSubmit={onSubmit}
            render={(props) => (
              <Form>
                <label>Nombre de la carpeta</label>
                <Field type="text" name="name" placeholder="Ej. Empresa H" className={cx(styles.input)} required />
                <label>RFC</label>
                <Field type="text" name="rfc" placeholder="AAA0000" className={cx(styles.input)} required/>
                {errorMessage ?
                  <div className={styles.error}>{errorMessage}</div> : null}
            
                <button type="submit" className={cx(styles.btn, styles.btnGreen, styles.addButton)}>
                  {clientId == 0 ? "Crear carpeta" : "Editar carpeta"}
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default FolderForm;