import React, { useState } from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { usePagination, useQuery } from "seed/gql";
import moment from "moment";
import { shortMonths } from 'components/util/constants';
import Loading from 'seed/helpers/Loading';

import styles from "styles/css/alerts/List.module.css";

const ALERTS = `
{
  alertPagination {
    totalPages
    alerts {
      date
      publication {
        rfc
        situation
        type
        name
        publishDate
      }
    }
  }
}
`

function AlertsList(props)
{
  const { path, url } = props.match;
  const { client_id } = props.match.params;
  const clientId = client_id != null ? client_id : sessionStorage.getItem("clientId");

  const [showAll, setShowAll] = useState(false)
  const qAlerts = usePagination(ALERTS, 1, !showAll ? 32 : 250, `transaction.client=${clientId}`, { orderBy: "-id" });
  
  if (qAlerts.loading) return <Loading />;
  if (qAlerts.error) return "Error";

  let { alerts = [], totalPages = 0 } = qAlerts.data.alertPagination;
  let alertsSorted = [...alerts]
  alertsSorted.sort((i1, i2) => (new Date(i2.date)).getTime() - (new Date(i1.date)).getTime())

  // UNIQUE ALERTS
  let unique = [];
  let uniqueRfcs = {}
  alertsSorted.forEach(function(i) {
    if(!uniqueRfcs[i.publication.rfc]) {
      uniqueRfcs[i.publication.rfc] = true;
      unique.push(i);
    }
  });
  alertsSorted = unique;

  const parseDate = (date) =>
  {
    const original = new Date(date);
    const d = new Date(original.getTime() + (new Date()).getTimezoneOffset() * 60000);
    return date ? `${moment(d).date()} ${shortMonths[moment(d).month()]}${d.getFullYear() < (new Date()).getFullYear() ? "." + d.getFullYear() : "" }` : "-"
  }

  let renderList = () =>
  alertsSorted
      .map((item, i) =>
      {
        const tempSit = item.publication.situation;
        const tempProp =
          tempSit === "DEFINITIVE"
            ? {
              icon: "fas fa-exclamation-triangle",
              color: "rgba(239, 71, 111, 1)",
              bkgColor: "rgba(239, 71, 111, 0.1)"
            }
            : tempSit === "ALLEGED"
              ? {
                icon: "fas fa-exclamation-circle",
                color: "rgba(255, 183, 77, 1)",
                bkgColor: "rgba(255, 183, 77, 0.1)"
              }
              : tempSit === "NO_LOCATED"
                ? {
                  icon: "fas fa-house-damage",
                  color: "rgba(255, 150, 77, 1)",
                  bkgColor: "rgba(255, 150, 77, 0.1)"
                }
                : {
                  icon: "fas fa-check-circle",
                  color: "rgba(150, 150, 150, 1)",
                  bkgColor: "rgba(150, 150, 150, 0.1)"
                };
        
          const description = item.publication.name.includes("//") ? 
            item.publication.name.substring(0, item.publication.name.indexOf("//")) : 
            item.publication.name

        return (
          <NavLink
            key={item.id}
            to={`${url}/alerts/${item.publication.rfc}/${item.id}`}
            className={cx(styles.listItem)}
            activeClassName={styles.activeListItem}>
            <p>
              <i className={tempProp.icon} style={{ color: tempProp.color }}></i>
              <label className={styles.itemTitle}>{parseDate(item.date)} <span>({item.publication.rfc})</span></label>
            </p>
            <label className={styles.itemSubtitle}>
              {description}
            </label>
          </NavLink>
        );
      });

  return (
    <div className={cx(styles.box)}>
      <div className={cx(styles.row, styles.content, styles.listContainer)}>
        {alertsSorted.length > 0 ? renderList() : <div className={styles.def}>Sin alertas</div>}
        {!showAll && alertsSorted.length > 30 ?
          <span
            onClick={() => setShowAll(true)}
            className={cx("btn", styles.btn)}>
            Mostrar todos
            </span> : null}
      </div>
    </div>
  );
}

export default AlertsList;