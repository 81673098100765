import React, { useState } from "react";
import Download from "components/alerts/Download";
import { Route } from "react-router-dom";
import cx from "classnames";
import AlertsList from "./List";
import Details from "./Details";
import Modal from 'seed/helpers/Modal';

import styles from "styles/css/alerts/Alerts.module.css";

function Alerts(props)
{
  const { path } = props.match;
  const [showDownload, setShowDownload] = useState(false);

  const DetailsModal = (props) =>
    <Modal {...props}
      width={700}
      height={600}>
      <Details {...props} />
    </Modal>

  const DownloadModal = (props) =>
    <Modal {...props}
      width={450}
      height={180}
      animation=""
      onClose={() => setShowDownload(false)}>
      <Download {...props} />
    </Modal>

  let alerts = true;
  return (
    <div className={cx("card", styles.heighthundred, styles.box)}>
      <div className={cx("card-header", styles.header, styles.row)}>
        <div className={cx(styles.titleContainer)}>
          <span className={cx(styles.title)}><i className="fas fa-bell"></i>Alertas</span>
          <span
            onClick={() => setShowDownload(true)}
            className={cx(
              "btn",
              styles.btn,
            )}>
            Descargar Excel
            </span>
        </div>
      </div>
      <div className={cx(styles.row, styles.content, styles.listContainer)}>
        <div className={cx("list-group", styles.list)}>
          <AlertsList alerts={alerts} {...props} />
        </div>
      </div>
      <Route path={`${path}/alerts/:rfc/:alert_id(\\d+)`}
        component={DetailsModal} />
      {showDownload ? <DownloadModal /> : null}
    </div>
  );
}

export default Alerts;
