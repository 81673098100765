import React, { useState } from "react";
import { useDetail } from 'seed/gql';
import { Link } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import cx from "classnames";
import styles from "styles/css/navigation/Toolbar.module.css";

const USER = `
{
  user {
    firstName
    lastName
  }
}
`


const Toolbar = (props) =>
{
  const { url } = props.match;
  const { search, setSearch } = props;
  const [state, setState] = useState({});
  const userId = sessionStorage.getItem("id");
  const qUser = useDetail(USER, userId);
  const { user = {} } = qUser.data;

  const openOptionMenu = e =>
    setState({ optionMenu: e.currentTarget });

  const closeOptionMenu = () =>
    setState({ optionMenu: null });

  const onClickLogout = () =>
  {
    props.history.push("/logout");
    closeOptionMenu();
  }

  const onClickNotifications = () =>
  {
    props.history.push(`${url}/notifications`);
    closeOptionMenu();
  }

  const onClickTerms = () =>
  {
    props.history.push("/terms");
    closeOptionMenu();
  }

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.flexBoxContainer)}>
        <div className={cx(styles.left)}>
          <div className={styles.dots}></div>
          <div className={cx(styles.logoContainer)}>
            <Link to ="/">
            <img
              className={styles.logo}
              src={"/resources/images/smp.png"}
              alt="Logo" />
            <img
              className={styles.crz}
              src={"/resources/images/logo.png"}
              alt="Logo" />
              </Link>
          </div>
          <div className={cx(styles.titleContainer)}>
          Sistema de Monitoreo de Proveedores</div>
        </div>
        <div className={cx(styles.right)}>
          <div className={cx("input-group", styles.searchBar)}>
            <input
              type="search"
              className={cx("form-control")}
              placeholder="Buscar Art. 69 & 69-B CFF"
              aria-describedby="basic-addon2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className={cx("input-group-append")}>
              <span
                className={cx(
                  "input-group-text",
                  styles.inputIcon,
                  styles.normalizeAppend
                )}
                id="basic-addon2"
              >
                <i className="fas fa-search"></i>
              </span>
            </div>
          </div>
          <div className={styles.profile}>
            <div
              className={styles.image}
              style={{
                backgroundImage:
                  "url(https://www.1spotmedia.com/assets/theme/rjr/images/profile.png)"
              }}
            ></div>
            <div className={styles.username}
              onClick={openOptionMenu}>
              <div>
                <span className={styles.name}>{user.firstName} {user.lastName}</span>
              </div>
              <div>
                <span className={styles.type}>Usuario <i class="fas fa-caret-down"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Menu
        anchorEl={state.optionMenu}
        open={Boolean(state.optionMenu)}
        onClose={closeOptionMenu}>
        <MenuItem onClick={onClickNotifications}>Manejo de notificaciones</MenuItem>
        <MenuItem onClick={onClickTerms}>Términos y condiciones</MenuItem>
        <MenuItem onClick={onClickLogout}>Cerrar sesión</MenuItem>
      </Menu>

    </div>
  );
};

export default Toolbar;
