import React, { useEffect, useState } from "react";
import { useGetCall } from 'seed/api';
import cx from "classnames";
import ToolbarAdmin from 'components/navigation/ToolbarAdmin';
import Admin from 'components/admin/Admin';
import Search from 'components/search/Search';

import styles from "styles/css/HomeAdmin.module.css";


function HomeAdmin(props)
{
  const [search, setSearch] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: (data) => {
      setIsAuth(true);
    },
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/socios");
    }
  })

  useEffect(() =>
  {
    const userId = sessionStorage.getItem('id');
    const role = sessionStorage.getItem('role');
    if (userId == null)
      return props.history.replace(`/socios`);
    if (role != "admin")
      return props.history.replace(`/login`)
    callAuth();
  }, []);
  
  if (!isAuth) return null;


  return (
    <div className={cx(styles.module)}>
      <div className={cx(styles.box)}>
        <div>
          <ToolbarAdmin {...props}
            search={search}
            setSearch={setSearch}
            className={cx(styles.row, styles.head)} />
        </div>
        <div className={cx(styles.home, "row")}>
          <div className={cx(styles.sidepanel, "col-md-2", "col-sm-1")}>
          </div>
          <div className={cx(styles.conent, "col-md-8", "col-sm-10")}>
          {search == "" ? 
              <Admin {...props}/> :
              <Search {...props}
                search={search}
                setSearch={setSearch}
              />
            }
          </div>
          <div className={cx(styles.sidepanel, "col-md-2", "col-sm-1")}>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAdmin;