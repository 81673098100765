import React, { useState } from 'react';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import styles from 'styles/css/transactions/misc/Filters.module.css';

import "react-datepicker/dist/react-datepicker.css";

function Filters(props)
{
  const [startDateR, setStartDateR] = useState(props.filterAdv[0]);
  const [endDateR, setEndDateR] = useState(props.filterAdv[1]);
  const [startDateP, setStartDateP] = useState(props.filterAdv[2]);
  const [endDateP, setEndDateP] = useState(props.filterAdv[3]);

  const onClickSend = () => {
    props.setFilterAdv([startDateR, endDateR, startDateP, endDateP]);
    props.setShowFilters(false);
  }

  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>Filtrado avanzado</h4>
      </div>

      <div className={styles.paper, styles.form}>
    
        <div className={styles.notice}>Fecha de Publicación (SAT)
          <br/>
            <DatePicker
            className={styles.date}  
            selected={startDateP}
            onChange={date => setStartDateP(date)}
            selectsStart
            showMonthYearPicker
            isClearable
            dateFormat="MMM yyyy"
            placeholderText="Fecha inicial"
            startDate={startDateP}
            endDate={endDateP}/>
          <br/>
          <DatePicker
            className={styles.date}
            selected={endDateP}
            onChange={date => setEndDateP(date)}
            selectsEnd
            showMonthYearPicker
            isClearable
            dateFormat="MMM yyyy"
            placeholderText="Fecha final"
            startDate={startDateP}
            endDate={endDateP}
            minDate={startDateP}/>
        </div>

        <div className={styles.notice}>Fecha de carga
        <br/>
          <DatePicker
            className={styles.date}
            selected={startDateR}
            onChange={date => setStartDateR(date)}
            selectsStart
            showMonthYearPicker
            isClearable
            dateFormat="MMM yyyy"
            placeholderText="Fecha inicial"
            startDate={startDateR}
            endDate={endDateR}/>
          <br/>
          <DatePicker
            className={styles.date}
            selected={endDateR}
            onChange={date => setEndDateR(date)}
            selectsEnd
            showMonthYearPicker
            isClearable
            dateFormat="MMM yyyy"
            placeholderText="Fecha final"
            startDate={startDateR}
            endDate={endDateR}
            minDate={startDateR}
          />
        </div>

        <button type="button"
            onClick={onClickSend}
            className={cx(styles.btn, styles.btnGreen, styles.fullBtn)}>
            Filtrar</button> 
      </div>
    </div>
  );
}

export default Filters;
