import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { useSave } from 'seed/gql';
import { usePost } from 'seed/api';
import * as queries from 'seed/gql/queries';
import { APP_URL } from 'settings';
import styles from 'styles/css/admin/AddClient.module.css';

function AddClient(props)
{
  const userId = sessionStorage.getItem("id");
  const [clientInfo, setClientInfo] = useState({})
  const [cSaveClient] = useSave(queries.SAVE_CLIENT, {
    onCompleted: data =>
    window.location.replace(APP_URL),
    onError: error =>
      alert("Ha ocurrido un error, prueba de nuevo")
  });
  const [cSavePromo] = useSave(queries.SAVE_PROMO_CODE, {
    onCompleted: data => {
      const promoId = data.savePromoCode.promoCode.id;
      cSaveClient(
      {
        name: clientInfo.name, promoCode: promoId,
        publicKey: "-", partner: "-",  hasTutorial: false
      })
    },
    onError: error => 
      alert("Ha ocurrido un error, prueba de nuevo")
  });
  const [cAutocomplete] = usePost("/clients/auto_complete", {
    onCompleted: data => {
      if (data.length > 0){
        let cStr = "";
        for(let client of data)
          cStr += `  - ${client.name}${client.parent != "" ? " <Carpeta>": ""} (Socio: ${client.partner})\n`;
        const al = window.confirm(`AVISO\nSe encontraron algunos clientes que podrían duplicarse, presiona OK para continuar:\n${cStr}` );
        if (al == null || al == false)
          return;
      }
      cSavePromo({value: clientInfo.promoCode, category: "GENERATED", used: false, user: parseInt(userId)})
    },
    onError: error => 
      alert("Ha ocurrido un error, prueba de nuevo")
  })

  
  const onSubmit = (e) =>
  {
    e.preventDefault();
    const name = e.target.name.value;
    let promoCode = name.substring(0,4).toUpperCase();
    let lenPromo = promoCode.length;
    for (let i = 0; i < 7 - lenPromo; i++){
      promoCode += Math.floor(Math.random() * 10) + ""
    }
    setClientInfo({
      name: name,
      promoCode: promoCode
    });
    cAutocomplete({name: name})
  }

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)} >
        <h4 style={{color: "white"}}>Nueva invitación</h4>
      </div>

      <div className={(styles.paper, styles.form)}>
      <form onSubmit={onSubmit}>
         
        <input name="name"
          type="text"
          placeholder="Nombre del cliente"
          className={cx(styles.fil, styles.fileFil)}/>

          <button type="submit"
            className={cx(styles.btn, styles.btnGreen, styles.fullBtn)}>
            Crear invitación</button>
          </form>

      </div>
    </div>
  );
}

export default AddClient;
