import React from 'react';
import cx from 'classnames';
import Loading from 'seed/helpers/Loading'
import { useQuery } from "seed/gql";
import styles from 'styles/css/alerts/Download.module.css';
import { CSVLink, CSVDownload } from "react-csv";


const ALERTS = `
{
  alerts {
    date
    publication {
      rfc
      situation
      type
      name
      publishDate
    }
  }
}
`

function Download(props)
{

  const getDateExcelFormat = (date) =>
  {
    try {
      const date1 = new Date(date);
      return new Date(date1.toUTCString());
    } catch (e){
      return date
    }
  }

  const getStatus = (situation) =>
  {
    if (situation == "DEFINITIVE") return "Definitivo";
    if (situation == "ALLEGED") return "Presunto";
    if (situation == "NO_LOCATED_REMOVED") return "Eliminado no localizado";
    if (situation == "NO_LOCATED") return "No localizado";
    if (situation == "DETRACTED") return "Desvirtuado";
    if (situation == "FAVORABLE") return "Sentencia favorable";
    return "OK";
  }


  const clientId = sessionStorage.getItem("clientId");

  const qAlerts = useQuery(ALERTS, `transaction.client=${clientId}`);
  if (qAlerts.loading)
    return <div className={styles.loading}><Loading /></div>

  let { alerts = [] } = qAlerts.data;

  let alertSorted = [...alerts]
  alertSorted.sort((i1, i2) => (new Date(i2.date)).getTime() - (new Date(i1.date)).getTime())

  // UNIQUE ALERTS
  let unique = [];
  let uniqueRfcs = {}
  alertSorted.forEach(function(i) {
    if(!uniqueRfcs[i.publication.rfc]) {
      uniqueRfcs[i.publication.rfc] = true;
      unique.push(i);
    }
  });
  alertSorted = unique;

  let csvData = []
  for (let alert of alertSorted) {

    const row = {
      "Fecha de alerta": getDateExcelFormat(alert.date),
      "RFC": alert.publication.rfc,
      "Nombre": alert.publication.name,
      "Situación": getStatus(alert.publication.situation),
      "Fuente": `DOF art. ${alert.publication.situation != "NO_LOCATED" && alert.publication.situation != "NO_LOCATED_REMOVED" ? "69-B" : "69"} Código Fiscal de la Federación`,
      "Fecha de publicación": getDateExcelFormat(alert.publication.publishDate)
    }
    csvData.push(row)
  }

  return (
    <div className={styles.module}>

      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>Alertas</h4>
      </div>
      <div className={styles.paper}>
        <CSVLink   filename={"alertas_crz.csv"} data={csvData} className={cx("btn",
            "btn-success", styles.btn, styles.btnSucess)}>Descargar Excel</CSVLink>    
      </div>
    </div>
  );
}

export default Download;
