import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { detect } from 'detect-browser';
import cx from 'classnames';
import styles from 'styles/css/auth/RegistryDoneNotif.module.css';

function RegistryDone(props)
{
  const admin = sessionStorage.getItem("tempAdmin");

  return (
    <div className={styles.module}>
      <div className={styles.background}>
        <div className={styles.dots} />
        <Link to="/">
          <img
            className={styles.logo}
            src={"/resources/images/smp.png"}
            alt="Logo" />
        </Link>

        <img
          className={styles.crz}
          src={"/resources/images/logo.png"}
          alt="Logo" />

        <div className={cx(styles.container, 'animated zoomIn')}>

          <label className={styles.title}>Llave de seguridad</label>
          <label className={styles.subtitle}>Para poder acceder al sistema es necesario una llave de seguridad (crz-llave.pem), contacta al primer titular de la cuenta para obtenerla<br/><a href={"mailto:" + admin}>{admin}</a></label>
         
          <Link to="/login"
            className={cx(styles.login)}>
            Iniciar sesión
          </Link>

        </div>

      </div>

      <p className={cx(styles.footer)}>
        Chevez, Ruiz, Zamarripa y Cia., S.C | <Link to="/terms">Términos y condiciones</Link> | <a href="mailto:soporte@chevez.com.mx">Ayuda</a>
      </p>

    </div >
  );
}

export default RegistryDone;
