import React from "react";
import cx from "classnames";
import { useQuery } from "seed/gql";
import moment from "moment";
import Loading from 'seed/helpers/Loading';

import styles from "styles/css/alerts/Details.module.css";
import { months } from 'components/util/constants';

const PUBLICATIONS = `
{
    publications {
      rfc
      situation
      type
      name
      publishDate
      dofPublications {  
      }
      satPublications {
        satPostDate
        dofPostDate
        satDetractedPostDate
        dofDetractedPostDate
        satDefinitivePostDate
        dofDefinitivePostDate
        satFavorablePostDate
        dofFavorablePostDate
      }
    }
}
`

function SearchDetails(props)
{
  const { rfc } = props.match.params;

  const qPublications = useQuery(PUBLICATIONS, `rfc=${rfc}`);
  if (qPublications.loading) return <Loading />;
  if (qPublications.error) return "Error";

  let { publications = [] } = qPublications.data;
   // In case of same date use id
  publications.sort((p1, p2) => 
    ((new Date(p1.publishDate)).getTime() - p1.id) - ((new Date(p2.publishDate)).getTime() - p2.id))
  if (publications.length == 0) return "Error";
  const publication = publications[0];
  
  
  const details = publication.type == "SAT" ? publication.satPublications[0] : publication.dofPublications[0];
  const tempSit = publication.situation;
  const tempProp =
    tempSit === "DEFINITIVE"
      ? {
        icon: "fas fa-exclamation-triangle",
        color: "rgba(239, 71, 111, 1)",
        bkgColor: "rgba(239, 71, 111, 0.1)"
      }
      : tempSit === "ALLEGED"
        ? {
          icon: "fas fa-exclamation-circle",
          color: "rgba(255, 183, 77, 1)",
          bkgColor: "rgba(255, 183, 77, 0.1)"
        }
        : tempSit === "NO_LOCATED"
          ? {
            icon: "fas fa-house-damage",
            color: "rgba(255, 150, 77, 1)",
            bkgColor: "rgba(255, 150, 77, 0.1)"
          }
          : {
            icon: "fas fa-check-circle",
            color: "rgba(150, 150, 150, 1)",
            bkgColor: "rgba(150, 150, 150, 0.1)"
          };

  const parseDate = (date) =>
  {
    const original = new Date(date);
    const d = new Date(original.getTime() + (new Date()).getTimezoneOffset() * 60000);
    return date ? `${moment(d).date()} de ${months[moment(d).month()]} ${moment(d).year()}` : "-"
  }

  const renderSat = (details) =>
    <table>
      <tbody>
        <tr>
          <td>Fuente de información:</td>
          <td>DOF art. {tempSit != "NO_LOCATED" ? "69-B" : "69"} Código Fiscal de la Federación</td>
        </tr>
        {tempSit != "NO_LOCATED" ?
          <tr>
            <td>Publicación - Presunto:</td>
            <td>{parseDate(details.satPostDate)}</td>
          </tr> : null}
        {tempSit == "DETRACTED" ?
          <tr>
            <td>Publicación - Desvirtuado:</td>
            <td>{parseDate(details.satDetractedDocDate)}</td>
          </tr> : null}
        {tempSit == "DEFINITIVE" ?
          <tr>
            <td>Publicación - Definitivo:</td>
            <td>{parseDate(details.satDefinitivePostDate)}</td>
          </tr> : null}
        {tempSit == "FAVORABLE" ?
          <tr>
            <td>Publicación - Favorable:</td>
            <td>{parseDate(details.satFavorablePostDate)}</td>
          </tr> : null}
        {tempSit == "NO_LOCATED" ?
          <tr>
            <td>Publicación - No localizado:</td>
            <td>{parseDate(details.satPostDate)}</td>
          </tr> : null}
      </tbody>
    </table>

  return (
    <div className={cx(styles.paper)}>
      <div className={cx(styles.box)}>
        <div
          className={cx(
            styles.row,
            styles.header,
            styles.mainHeader,
            styles.flexContainer
          )}>
          <div className={cx(styles.flexCol)}>
            <h5
              id="simple-modal-title"
              className={cx(styles.title, styles.modalTitle)}>
              Detalles
            </h5>
          </div>
        </div>
        <div
          className={cx(styles.row, styles.header, styles.flexContainer)}
          style={{
            backgroundColor: tempProp.bkgColor,
            paddingLeft: "12px",
            paddingRight: "12px"
          }}>
          <div>
            <i className={tempProp.icon} style={{ color: tempProp.color }}></i>
            &nbsp;&nbsp;{publication.rfc}
          </div>
          <div>{publication.name}</div>
        </div>
        <br />
        <div className={cx(styles.row, styles.header, styles.details)}>
          <div className={cx(styles.titleContainer)}>
            <h5 className={cx(styles.title)}>PUBLICACIONES</h5>
          </div>
          <div style={{ marginTop: "5px" }}>
            {publication.type == "SAT" ?
              renderSat(details) : null}
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default SearchDetails;
