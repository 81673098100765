import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import {IS_PROD, API_URL} from 'settings'
import styles from 'styles/css/auth/LoginAdmin.module.css';

function LoginAdmin(props)
{
  const redirect_url = API_URL + "/users/azure_auth"
  const base_url = "https://login.microsoftonline.com/5a3c2a26-df13-4524-9df2-07d9a4d4a7d0/oauth2/v2.0/authorize?client_id=a2852a12-bbfc-48e0-b694-999138377929&response_type=code&response_mode=query&scope=openid%20User.Read&prompt=select_account"
  const url = IS_PROD ? base_url : base_url + "&state=" + encodeURI("crz-link-" + redirect_url)

  return (
    <div className={styles.module}>

      <div className={styles.background}>
        <div className={styles.dots} />
        <Link to="/">
          <img
            className={styles.logo}
            src={"/resources/images/smp.png"}
            alt="Logo" />
        </Link>

        <img
          className={styles.crz}
          src={"/resources/images/logo.png"}
          alt="Logo" />

      <div className={cx(styles.container, 'animated zoomIn')}>

      <label className={styles.title}>Iniciar sesión<br/><span>Portal de Socios</span></label>
      
      <a href={url} className={styles.submit}>Iniciar sesión con Cuenta de Microsoft</a>
      
      </div>

      </div>

      <p className={cx(styles.footer)}>
        Chevez, Ruiz, Zamarripa y Cia., S.C | <Link to="/terms">Términos y condiciones</Link> | <a href="mailto:erick.rivas@chevez.com.mx">Ayuda</a>
      </p>

    </div>
  );
}

export default LoginAdmin;
