import React from 'react';
import cx from 'classnames';
import Alerts from 'components/alerts/Alerts'
import styles from 'styles/css/admin/Details.module.css';

function Details(props)
{
  

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>Alertas Cliente</h4>
      </div>

      <div className={(styles.paper, styles.form)}>
        <Alerts {...props} />
      </div>
    </div>
  );
}

export default Details;
