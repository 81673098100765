/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const ALERT = `
{
  alert {
    id
    date
    status
    transaction {
      id
    }
    publication {
      id
    }
  }
}
`;

export const SET_ALERT = `
mutation Set(
  $id: Int!,
  $date: DateTime,
  $status: String,
  $transaction: Int,
  $publication: Int,
)
{
  setAlert(
    id: $id,
    date: $date,
    status: $status,
    transaction: $transaction,
    publication: $publication,
  ) {
    alert {
      id
      date
      status
      transaction {
        id
      }
      publication {
        id
      }
    }
  }
}
`;

export const SAVE_ALERT = `
mutation Save(
  $date: DateTime!,
  $status: String!,
  $transaction: Int!,
  $publication: Int!,
)
{
  saveAlert(
    date: $date,
    status: $status,
    transaction: $transaction,
    publication: $publication,
  ) {
    alert {
      id
    }
  }
}
`;

export const DELETE_ALERT = `
mutation Delete($id: Int!)
{
  deleteAlert(id: $id)
  {
    id
  }
}
`;

export const CLIENT = `
{
  client {
    id
    name
    rfc
    publicKey
    partner
    hasTutorial
    updateStatus
    admin {
      id
    }
    notified {
      id
    }
    assigned {
      id
    }
    promoCode {
      id
    }
    parentClient {
      id
    }
  }
}
`;

export const SET_CLIENT = `
mutation Set(
  $id: Int!,
  $name: String,
  $rfc: String,
  $publicKey: String,
  $partner: String,
  $hasTutorial: Boolean,
  $updateStatus: String,
  $admin: Int,
  $notified: [Int],
  $assigned: [Int],
  $promoCode: Int,
  $parentClient: Int,
)
{
  setClient(
    id: $id,
    name: $name,
    rfc: $rfc,
    publicKey: $publicKey,
    partner: $partner,
    hasTutorial: $hasTutorial,
    updateStatus: $updateStatus,
    admin: $admin,
    notified: $notified,
    assigned: $assigned,
    promoCode: $promoCode,
    parentClient: $parentClient,
  ) {
    client {
      id
      name
      rfc
      publicKey
      partner
      hasTutorial
      updateStatus
      admin {
        id
      }
      notified {
        id
      }
      assigned {
        id
      }
      promoCode {
        id
      }
      parentClient {
        id
      }
    }
  }
}
`;

export const SAVE_CLIENT = `
mutation Save(
  $name: String!,
  $rfc: String,
  $publicKey: String!,
  $partner: String!,
  $hasTutorial: Boolean!,
  $updateStatus: String,
  $admin: Int,
  $notified: [Int],
  $assigned: [Int],
  $promoCode: Int,
  $parentClient: Int,
)
{
  saveClient(
    name: $name,
    rfc: $rfc,
    publicKey: $publicKey,
    partner: $partner,
    hasTutorial: $hasTutorial,
    updateStatus: $updateStatus,
    admin: $admin,
    notified: $notified,
    assigned: $assigned,
    promoCode: $promoCode,
    parentClient: $parentClient,
  ) {
    client {
      id
    }
  }
}
`;

export const DELETE_CLIENT = `
mutation Delete($id: Int!)
{
  deleteClient(id: $id)
  {
    id
  }
}
`;

export const DOF_PUBLICATION = `
{
  dofPublication {
    id
    registryNumber
    docNumber
    docDate
    authorityName
    authorityDate
    effectiveAuthorityDate
    personalDate
    effectivePersonalDate
    mailboxDate
    effectiveMailboxDate
    publication {
      id
    }
  }
}
`;

export const SET_DOF_PUBLICATION = `
mutation Set(
  $id: Int!,
  $registryNumber: Int,
  $docNumber: String,
  $docDate: DateTime,
  $authorityName: String,
  $authorityDate: DateTime,
  $effectiveAuthorityDate: DateTime,
  $personalDate: DateTime,
  $effectivePersonalDate: DateTime,
  $mailboxDate: DateTime,
  $effectiveMailboxDate: DateTime,
  $publication: Int,
)
{
  setDofPublication(
    id: $id,
    registryNumber: $registryNumber,
    docNumber: $docNumber,
    docDate: $docDate,
    authorityName: $authorityName,
    authorityDate: $authorityDate,
    effectiveAuthorityDate: $effectiveAuthorityDate,
    personalDate: $personalDate,
    effectivePersonalDate: $effectivePersonalDate,
    mailboxDate: $mailboxDate,
    effectiveMailboxDate: $effectiveMailboxDate,
    publication: $publication,
  ) {
    dofPublication {
      id
      registryNumber
      docNumber
      docDate
      authorityName
      authorityDate
      effectiveAuthorityDate
      personalDate
      effectivePersonalDate
      mailboxDate
      effectiveMailboxDate
      publication {
        id
      }
    }
  }
}
`;

export const SAVE_DOF_PUBLICATION = `
mutation Save(
  $registryNumber: Int!,
  $docNumber: String!,
  $docDate: DateTime!,
  $authorityName: String!,
  $authorityDate: DateTime!,
  $effectiveAuthorityDate: DateTime!,
  $personalDate: DateTime!,
  $effectivePersonalDate: DateTime!,
  $mailboxDate: DateTime!,
  $effectiveMailboxDate: DateTime!,
  $publication: Int!,
)
{
  saveDofPublication(
    registryNumber: $registryNumber,
    docNumber: $docNumber,
    docDate: $docDate,
    authorityName: $authorityName,
    authorityDate: $authorityDate,
    effectiveAuthorityDate: $effectiveAuthorityDate,
    personalDate: $personalDate,
    effectivePersonalDate: $effectivePersonalDate,
    mailboxDate: $mailboxDate,
    effectiveMailboxDate: $effectiveMailboxDate,
    publication: $publication,
  ) {
    dofPublication {
      id
    }
  }
}
`;

export const DELETE_DOF_PUBLICATION = `
mutation Delete($id: Int!)
{
  deleteDofPublication(id: $id)
  {
    id
  }
}
`;

export const PROMO_CODE = `
{
  promoCode {
    id
    value
    category
    used
    user {
      id
    }
  }
}
`;

export const SET_PROMO_CODE = `
mutation Set(
  $id: Int!,
  $value: String,
  $category: String,
  $used: Boolean,
  $user: Int,
)
{
  setPromoCode(
    id: $id,
    value: $value,
    category: $category,
    used: $used,
    user: $user,
  ) {
    promoCode {
      id
      value
      category
      used
      user {
        id
      }
    }
  }
}
`;

export const SAVE_PROMO_CODE = `
mutation Save(
  $value: String!,
  $category: String!,
  $used: Boolean!,
  $user: Int!,
)
{
  savePromoCode(
    value: $value,
    category: $category,
    used: $used,
    user: $user,
  ) {
    promoCode {
      id
    }
  }
}
`;

export const DELETE_PROMO_CODE = `
mutation Delete($id: Int!)
{
  deletePromoCode(id: $id)
  {
    id
  }
}
`;

export const PUBLICATION = `
{
  publication {
    id
    rfc
    rfcHash
    name
    situation
    publishDate
    type
  }
}
`;

export const SET_PUBLICATION = `
mutation Set(
  $id: Int!,
  $rfc: String,
  $rfcHash: String,
  $name: String,
  $situation: String,
  $publishDate: DateTime,
  $type: String,
)
{
  setPublication(
    id: $id,
    rfc: $rfc,
    rfcHash: $rfcHash,
    name: $name,
    situation: $situation,
    publishDate: $publishDate,
    type: $type,
  ) {
    publication {
      id
      rfc
      rfcHash
      name
      situation
      publishDate
      type
    }
  }
}
`;

export const SAVE_PUBLICATION = `
mutation Save(
  $rfc: String!,
  $rfcHash: String!,
  $name: String!,
  $situation: String!,
  $publishDate: DateTime!,
  $type: String!,
)
{
  savePublication(
    rfc: $rfc,
    rfcHash: $rfcHash,
    name: $name,
    situation: $situation,
    publishDate: $publishDate,
    type: $type,
  ) {
    publication {
      id
    }
  }
}
`;

export const DELETE_PUBLICATION = `
mutation Delete($id: Int!)
{
  deletePublication(id: $id)
  {
    id
  }
}
`;

export const SAT_PUBLICATION = `
{
  satPublication {
    id
    registryNumber
    satDocNumber
    satDocDate
    satPostDate
    dofDocNumber
    dofDocDate
    dofPostDate
    satDetractedPostDate
    satDetractedDocNumber
    satDetractedDocDate
    dofDetractedPostDate
    satDefinitiveDocNumber
    satDefinitiveDocDate
    satDefinitivePostDate
    dofDefinitivePostDate
    satFavorableDocNumber
    satFavorableDocDate
    satFavorablePostDate
    dofFavorableDocNumber
    dofFavorableDocDate
    dofFavorablePostDate
    publication {
      id
    }
  }
}
`;

export const SET_SAT_PUBLICATION = `
mutation Set(
  $id: Int!,
  $registryNumber: Int,
  $satDocNumber: String,
  $satDocDate: DateTime,
  $satPostDate: DateTime,
  $dofDocNumber: String,
  $dofDocDate: DateTime,
  $dofPostDate: DateTime,
  $satDetractedPostDate: DateTime,
  $satDetractedDocNumber: String,
  $satDetractedDocDate: DateTime,
  $dofDetractedPostDate: DateTime,
  $satDefinitiveDocNumber: String,
  $satDefinitiveDocDate: DateTime,
  $satDefinitivePostDate: DateTime,
  $dofDefinitivePostDate: DateTime,
  $satFavorableDocNumber: String,
  $satFavorableDocDate: DateTime,
  $satFavorablePostDate: DateTime,
  $dofFavorableDocNumber: String,
  $dofFavorableDocDate: DateTime,
  $dofFavorablePostDate: DateTime,
  $publication: Int,
)
{
  setSatPublication(
    id: $id,
    registryNumber: $registryNumber,
    satDocNumber: $satDocNumber,
    satDocDate: $satDocDate,
    satPostDate: $satPostDate,
    dofDocNumber: $dofDocNumber,
    dofDocDate: $dofDocDate,
    dofPostDate: $dofPostDate,
    satDetractedPostDate: $satDetractedPostDate,
    satDetractedDocNumber: $satDetractedDocNumber,
    satDetractedDocDate: $satDetractedDocDate,
    dofDetractedPostDate: $dofDetractedPostDate,
    satDefinitiveDocNumber: $satDefinitiveDocNumber,
    satDefinitiveDocDate: $satDefinitiveDocDate,
    satDefinitivePostDate: $satDefinitivePostDate,
    dofDefinitivePostDate: $dofDefinitivePostDate,
    satFavorableDocNumber: $satFavorableDocNumber,
    satFavorableDocDate: $satFavorableDocDate,
    satFavorablePostDate: $satFavorablePostDate,
    dofFavorableDocNumber: $dofFavorableDocNumber,
    dofFavorableDocDate: $dofFavorableDocDate,
    dofFavorablePostDate: $dofFavorablePostDate,
    publication: $publication,
  ) {
    satPublication {
      id
      registryNumber
      satDocNumber
      satDocDate
      satPostDate
      dofDocNumber
      dofDocDate
      dofPostDate
      satDetractedPostDate
      satDetractedDocNumber
      satDetractedDocDate
      dofDetractedPostDate
      satDefinitiveDocNumber
      satDefinitiveDocDate
      satDefinitivePostDate
      dofDefinitivePostDate
      satFavorableDocNumber
      satFavorableDocDate
      satFavorablePostDate
      dofFavorableDocNumber
      dofFavorableDocDate
      dofFavorablePostDate
      publication {
        id
      }
    }
  }
}
`;

export const SAVE_SAT_PUBLICATION = `
mutation Save(
  $registryNumber: Int!,
  $satDocNumber: String!,
  $satDocDate: DateTime!,
  $satPostDate: DateTime!,
  $dofDocNumber: String,
  $dofDocDate: DateTime,
  $dofPostDate: DateTime,
  $satDetractedPostDate: DateTime,
  $satDetractedDocNumber: String,
  $satDetractedDocDate: DateTime,
  $dofDetractedPostDate: DateTime,
  $satDefinitiveDocNumber: String,
  $satDefinitiveDocDate: DateTime,
  $satDefinitivePostDate: DateTime,
  $dofDefinitivePostDate: DateTime,
  $satFavorableDocNumber: String,
  $satFavorableDocDate: DateTime,
  $satFavorablePostDate: DateTime,
  $dofFavorableDocNumber: String,
  $dofFavorableDocDate: DateTime,
  $dofFavorablePostDate: DateTime,
  $publication: Int!,
)
{
  saveSatPublication(
    registryNumber: $registryNumber,
    satDocNumber: $satDocNumber,
    satDocDate: $satDocDate,
    satPostDate: $satPostDate,
    dofDocNumber: $dofDocNumber,
    dofDocDate: $dofDocDate,
    dofPostDate: $dofPostDate,
    satDetractedPostDate: $satDetractedPostDate,
    satDetractedDocNumber: $satDetractedDocNumber,
    satDetractedDocDate: $satDetractedDocDate,
    dofDetractedPostDate: $dofDetractedPostDate,
    satDefinitiveDocNumber: $satDefinitiveDocNumber,
    satDefinitiveDocDate: $satDefinitiveDocDate,
    satDefinitivePostDate: $satDefinitivePostDate,
    dofDefinitivePostDate: $dofDefinitivePostDate,
    satFavorableDocNumber: $satFavorableDocNumber,
    satFavorableDocDate: $satFavorableDocDate,
    satFavorablePostDate: $satFavorablePostDate,
    dofFavorableDocNumber: $dofFavorableDocNumber,
    dofFavorableDocDate: $dofFavorableDocDate,
    dofFavorablePostDate: $dofFavorablePostDate,
    publication: $publication,
  ) {
    satPublication {
      id
    }
  }
}
`;

export const DELETE_SAT_PUBLICATION = `
mutation Delete($id: Int!)
{
  deleteSatPublication(id: $id)
  {
    id
  }
}
`;

export const TRANSACTION = `
{
  transaction {
    id
    date
    rfc
    rfcHash
    client {
      id
    }
  }
}
`;

export const SET_TRANSACTION = `
mutation Set(
  $id: Int!,
  $date: DateTime,
  $rfc: String,
  $rfcHash: String,
  $client: Int,
)
{
  setTransaction(
    id: $id,
    date: $date,
    rfc: $rfc,
    rfcHash: $rfcHash,
    client: $client,
  ) {
    transaction {
      id
      date
      rfc
      rfcHash
      client {
        id
      }
    }
  }
}
`;

export const SAVE_TRANSACTION = `
mutation Save(
  $date: DateTime,
  $rfc: String!,
  $rfcHash: String!,
  $client: Int!,
)
{
  saveTransaction(
    date: $date,
    rfc: $rfc,
    rfcHash: $rfcHash,
    client: $client,
  ) {
    transaction {
      id
    }
  }
}
`;

export const DELETE_TRANSACTION = `
mutation Delete($id: Int!)
{
  deleteTransaction(id: $id)
  {
    id
  }
}
`;

export const USER = `
{
  user {
    id
    username
    firstName
    lastName
    email
    isActive
    phone
    recoverToken
    type
  }
}
`;

export const SET_USER = `
mutation Set(
  $id: Int!,
  $username: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $isActive: Boolean,
  $password: String,
  $phone: String,
  $recoverToken: String,
  $type: String,
)
{
  setUser(
    id: $id,
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    phone: $phone,
    recoverToken: $recoverToken,
    type: $type,
  ) {
    user {
      id
      username
      firstName
      lastName
      email
      isActive
      phone
      recoverToken
      type
    }
  }
}
`;

export const SAVE_USER = `
mutation Save(
  $username: String!,
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $isActive: Boolean!,
  $password: String!,
  $phone: String,
  $recoverToken: String!,
  $type: String!,
)
{
  saveUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    phone: $phone,
    recoverToken: $recoverToken,
    type: $type,
  ) {
    user {
      id
    }
  }
}
`;

export const DELETE_USER = `
mutation Delete($id: Int!)
{
  deleteUser(id: $id)
  {
    id
  }
}
`;