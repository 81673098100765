import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api';
import { API_URL } from 'settings';
import Loading from 'seed/helpers/Loading';
import { encrypt, hasht } from 'components/util/security';
import styles from 'styles/css/transactions/Upload.module.css';

function UploadForm(props)
{
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false)
  const fileRef = useRef(null);

  const [cSync, qSync] = usePost("/transactions/sync", {
    onCompleted: data =>
    {
      window.location.href = "/app";
    },
    onError: data => alert("Ha ocurrido un error al cargar tus datos")
  })

  if (qSync.loading || loading) return (
    <div className={styles.loading}>
      <Loading />
      <div className={styles.status}>{loading ? "Encriptando datos" : "Cargando datos"} <br />puede tomar unos minutos...</div>
    </div>);

  const handleInputFileChange = e =>
  {
    e.preventDefault();
    let file = fileRef.current.files[0];
    let re = /(\.csv)$/i;
    if (file && re.exec(file.name)) {
      let reader = new FileReader();
      reader.onload = event =>
      {
        let data = event.target.result;
        setCsvData(data);
      }
      reader.readAsText(file);
    } else alert("Failed to load key")
  }

  const onClickSend = () =>
  {
    setLoading(true);
    setTimeout(function ()
    {
      const clientId = sessionStorage.getItem("clientId");
      const rows = csvData.split("\n");
      const res = [['rfc', 'rfc_hash', 'date']];
      for (let i = 1; i < rows.length; i++) {
        let cols = rows[i].split(",");
        res.push([
          encrypt(cols[0].toUpperCase().trim()),
          hasht(cols[0].toUpperCase().trim()),
          ""
        ]);
      }
      let csvRaw = res.join("\n");
      setLoading(false);
      cSync({ csv_raw: csvRaw, client_id: clientId });
    }, 0)


  }

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4 style={{color: "white"}}>Carga de datos</h4>
      </div>

      <div className={(styles.paper, styles.form)}>
        <div className={styles.notice}>
          <span>INSTRUCCIONES</span>
          <ol>
            <li>Descarga la siguiente plantilla <br />
              <a className={styles.download}
                href={`${API_URL.substring(0, API_URL.length - 4)}/static/plantilla-proveedores.csv`}>
                Descargar
          </a></li>
            <li>Abre el archivo en excel</li>
            <li>Rellena & <b style={{ color: "#222" }}>Guarda</b> los datos</li>
            <li>Sube el archivo llenado</li>
          </ol>
        </div>

        <span>Subir archivo</span>
        <input name="key"
          type="file"
          ref={fileRef}
          placeholder="Seleccione un archivo"
          accept=".csv"
          className={cx(styles.fil, styles.fileFil)}
          onChange={handleInputFileChange} />

        {csvData ?
          <button type="button"
            onClick={onClickSend}
            className={cx(styles.btn, styles.btnGreen, styles.fullBtn)}>
            Cargar datos</button> : null
        }

      </div>
    </div>
  );
}

export default UploadForm;
