import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { detect } from 'detect-browser';
import cx from 'classnames';
import styles from 'styles/css/auth/RegistryDone.module.css';

function RegistryDone(props)
{
  const [showLogin, setShowLogin] = useState(false);
  const privateKey = sessionStorage.getItem("tempPrivateKey");

  const onClickKey = () =>
  {
    setShowLogin(true);
    return false;
  }

  const onClickKeyIE = () =>
  {
    var blobObject = new Blob([privateKey]);
    window.navigator.msSaveBlob(blobObject, 'crz-llave.pem');
    setShowLogin(true);
  }

  return (
    <div className={styles.module}>
      <div className={styles.background}>
        <div className={styles.dots} />
        <Link to="/">
          <img
            className={styles.logo}
            src={"/resources/images/smp.png"}
            alt="Logo" />
        </Link>

        <img
          className={styles.crz}
          src={"/resources/images/logo.png"}
          alt="Logo" />

        <div className={cx(styles.container, 'animated zoomIn')}>

          <label className={styles.title}>Llave de seguridad</label>
          <label className={styles.subtitle}>La llave de seguridad es un archivo para proteger tu información y que solo tú puedas acceder a ella</label>
          <label className={styles.warning}>IMPORTANTE<br /> ALMACENA CON CUIDADO ESTE ARCHIVO PORQUE SOLO SE PUEDE GENERAR UNA VEZ.</label>
          <div className={styles.content}>
            {detect().name == "ie" || detect().name == "edge" ?
              <a
                onClick={onClickKeyIE}
                href="javascript:void(0)"
                className={styles.download}>
                Descargar</a>
              :
              <a
                onClick={onClickKey}
                href={`data:text/plain;charset=UTF-8,${privateKey}`}
                className={styles.download} download={"crz-llave.pem"}>
                Descargar</a>}

          </div>

          {showLogin ?
            <Link to="/login"
              className={cx(styles.login, "animated fadeIn")}>
              Iniciar sesión
            </Link> : null}

        </div>

      </div>

      <p className={cx(styles.footer)}>
        Chevez, Ruiz, Zamarripa y Cia., S.C | <Link to="/terms">Términos y condiciones</Link> | <a href="mailto:soporte@chevez.com.mx">Ayuda</a>
      </p>

    </div >
  );
}

export default RegistryDone;
