import React, { useEffect } from 'react';
import { usePost } from 'seed/api'
import styles from 'styles/css/auth/Logout.module.css';

function Logout(props)
{
  const [logout] = usePost("/auth/logout", {
    onCompleted: data =>
    {
      const role = sessionStorage.getItem("role")
      sessionStorage.clear();
      if (role == "admin")
        return props.history.replace('/socios');
      return props.history.replace('/login');
    }
  });

  useEffect(() => logout(), []);

  return <div className={styles.module}></div>;
}

export default Logout;
